import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoryReduxConstant } from "../../../constants";

const AddCategoriesModal = ({
  data,
  title,
  onClose,
  onConfirm,
  isSubCategory,
}) => {
  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const isSpinnerRunning = () => {
    return (
      spinnerActions.includes(categoryReduxConstant.CREATE_CATEGORY) ||
      spinnerActions.includes(categoryReduxConstant.UPDATE_CATEGORY)
    );
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
    disabled: isSpinnerRunning(),
  });
  const closeModal = () => {
    reset();
    onClose();
  };

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-3">
            <Form.Group>
              <Form.Control
                type="text"
                name="categoryName"
                className="h-12 rounded-md"
                placeholder="Category Name"
                {...register("categoryName", { required: true })}
                isInvalid={errors.categoryName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="description"
                className="h-12 rounded-md"
                placeholder="Description"
                {...register("description", { required: true })}
                isInvalid={errors.description}
              />
            </Form.Group>
            <Form.Group>
              {isSubCategory ? (
                <Form.Control
                  type="text"
                  readOnly
                  className="h-12 rounded-md"
                  {...register("division")}
                />
              ) : (
                <Form.Select
                  {...register("division", { required: true })}
                  isInvalid={errors.division}
                  className="h-12 rounded-md"
                >
                  <option value="">Select Division</option>
                  <option value="Men">Men</option>
                  <option value="Women">Woman</option>
                </Form.Select>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Select
                {...register("isActive", { required: true })}
                isInvalid={errors.isActive}
                className="h-12 rounded-md"
              >
                <option value="">Category Status</option>
                <option value="true">Active</option>
                <option value="false">InActive</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              type="button"
              onClick={closeModal}
              disabled={isSpinnerRunning()}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600 disabled:bg-slate-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSpinnerRunning()}
              className="bg-[#29D30A] w-full h-12 text-white rounded disabled:bg-[#bcfab1]"
            >
              {data?.categoryName ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddCategoriesModal;
