import CryptoJS from "crypto-js";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemTolocalStorage,
} from "../shared/utility";

export const encryptObj = (key, obj) => {
  const encryptedObj = CryptoJS.AES.encrypt(
    JSON.stringify(obj),
    "my-CIWUW-Secr@t_Key-!234"
  ).toString();
  setItemTolocalStorage(key, encryptedObj);
};

export const decryptObj = (encryptedString, key = "") => {
  try {
    if (encryptedString) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedString,
        "my-CIWUW-Secr@t_Key-!234"
      );
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else {
      return {};
    }
  } catch (e) {
    removeItemFromLocalStorage(key);
    window.location.reload();
  }
};

export const getDecryptedPayload = () => {
  const encyptedPayload = getItemFromLocalStorage("payload") || "";
  return decryptObj(encyptedPayload, "payload");
};
