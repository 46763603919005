import { activityLogsReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { GET_ALL_ACTIVITY_LOGS } = activityLogsReduxConstants;

const initialState = {
  allActivityLogs: [],
  activityLogsPagination: { pageSize: 10, totalElements: 0, pageNumber: 0 },
};

export const activityLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_ACTIVITY_LOGS):
      return {
        ...state,
        allActivityLogs: action.payload.data,
        activityLogsPagination:
          action.payload.page || initialState.activityLogsPagination,
      };
    default:
      return state;
  }
};
