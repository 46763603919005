// src/api/api.js
import axios from "axios";
import { getDecryptedPayload } from "./encryption";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Update with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include custom headers
api.interceptors.request.use(
  (config) => {
    // Modify headers as needed
    const payload = getDecryptedPayload(); // Assuming token is stored in localStorage
    if (payload.token) {
      config.headers.Authorization = `Bearer ${payload.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor if needed
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error?.response?.status;

    if (status === 401) {
      localStorage.clear();
      window.location.assign("/login");
    }

    // Handle error
    return Promise.reject(
      error?.response.data || { message: "Something went wrong!" }
    );
  }
);

export default api;
