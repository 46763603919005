import { toast } from "react-toastify";
import api from "../../../system/api";
import { failureAction, successAction } from "../../../shared/utility";
import { orderReduxConstant, systemReduxContant } from "../../../constants";

const { GET_ALL_ORDERS, UPDATE_ORDER, ORDER_DETAILS } = orderReduxConstant;

const getAllOrders = (params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_ORDERS));
  api
    .get("/order/getAllOrders", { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_ORDERS, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
      },
      (error) => {
        dispatch(failureAction(GET_ALL_ORDERS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_ORDERS)
      );
    });
};
const updateOrder = (id, body, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_ORDER));
  api
    .put(`/order/updateOrder/${id}`, body)
    .then(
      (response) => {
        dispatch(successAction(UPDATE_ORDER, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
      },
      (error) => {
        dispatch(failureAction(UPDATE_ORDER, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_ORDER)
      );
    });
};

const getOrderDetail = (id) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, ORDER_DETAILS));
  api
    .get(`/order/getOrderDetail/${id}`)
    .then(
      (response) => {
        dispatch(successAction(ORDER_DETAILS, response));
      },
      (error) => {
        dispatch(failureAction(ORDER_DETAILS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, ORDER_DETAILS)
      );
    });
};

export const orderActions = {
  updateOrder,
  getAllOrders,
  getOrderDetail,
};
