import api from "../../system/api";
import { failureAction, successAction } from "../../shared/utility";
import { customersReduxConstants, systemReduxContant } from "../../constants";
import { toast } from "react-toastify";

const { GET_ALL_CUSTOMERS } = customersReduxConstants;

const getAllCustomers = (params) => async (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_CUSTOMERS)
  );
  api
    .get(`/user/getAllUsers`, {
      params: params,
    })
    .then(
      (response) => {
        dispatch(
          successAction(GET_ALL_CUSTOMERS, {
            ...response,
            pageNumber: params.page,
          })
        );
      },
      (error) => {
        dispatch(failureAction(GET_ALL_CUSTOMERS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_CUSTOMERS)
      );
    });
};

export const customersActions = {
  getAllCustomers,
};
