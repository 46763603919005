import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import { orderPageFilters } from "../../constants";
import { orderActions } from "../../redux/actions";
import Header from "../../shared/components/header/header";
import { convertToReadableDate } from "../../shared/utility";
import ReactTable from "../../shared/components/reactTable/reactTable";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import { getUpdateOrderModal } from "../../shared";

const Orders = () => {
  const dispatch = useDispatch();
  const { allOrders, ordersPagination } = useSelector(
    (state) => state.ordersReducer
  );

  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "createdAt", desc: true }]);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const columns = useMemo(
    () => [
      {
        accessorKey: "orderId",
        header: () => "Id",
      },
      {
        accessorKey: "user.username",
        header: () => "Name",
      },
      {
        accessorKey: "address",
        header: "Address",
        cell: ({ row }) => (
          <div className="">
            <p
              className="p-0 m-0 truncate-text"
              title={`${row.original.address.address}, ${row.original.address.city}, ${row.original.address.province}`}
            >
              {row.original.address.address}, {row.original.address.city},{" "}
              {row.original.address.province}
            </p>
          </div>
        ),
      },
      {
        accessorKey: "user.email",
        header: "Email",
      },
      {
        accessorKey: "totalPrice",
        header: "Total Price",
        cell: ({ row: { original } }) =>
          `£${(
            original.products.reduce(
              (total, product) => (total + product.price) * product.quantity,
              0
            ) /
              100 +
            original.deliveryCharges
          ).toFixed(2)}`,
      },
      {
        accessorKey: "createdAt",
        header: () => "Order Placed At",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue === "completed" ? (
                <span className="text-green-600">Completed</span>
              ) : columnValue === "pending" ? (
                <span className="text-yellow-600">Pending</span>
              ) : columnValue === "refunded" ? (
                <span className="text-orange-600">Refunded</span>
              ) : (
                <span className="text-red-600 ">Canceled</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "Actions",
        cell: ({ row: { original } }) => {
          return (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                role="button"
                onClick={() => getOrderDetailModal(original)}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNumber, searchValue) => {
    dispatch(
      orderActions.getAllOrders({
        page: pageNumber,
        limit: 10,
        searchQuery: searchValue || search,
        status: filter,
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const getOrderDetailModal = (data) => {
    const dataToSend = {
      address: `${data.address.address}, ${data.address.city}, ${data.address.province}`,
      createdAt: `${convertToReadableDate(data.createdAt)}`,
      orderQuantity: data.orderQuantity,
      totalPrice:
        data.products.reduce(
          (previous, current) => (previous + current.price) * current.quantity,
          0
        ) / 100,
      status: data.status,
      email: data.user.email,
      products: data.products,
      supplierLink: data.supplier_link,
      deliveryCharges: data.deliveryCharges,
    };
    setComponent(
      getUpdateOrderModal({
        data: dataToSend,
        title: "Order Details",
        onClose: closeModal,
        onConfirm: (formData) => {
          dispatch(
            orderActions.updateOrder(
              data._id,
              { status: formData.status },
              () => {
                closeModal();
                getData(ordersPagination.pageNumber, search);
              }
            )
          );
        },
      })
    );
  };

  const onRefresh = () => {
    getData(1);
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting[0]?.id, sorting[0]?.desc, filter]);

  return (
    <Container fluid className="pb-3">
      {showModal && modalElement}
      <Header
        title="Orders"
        search={search}
        filter={filter}
        setOnSearch={setSearch}
        setFilter={onFilterChange}
        filters={orderPageFilters}
        refreshButtonName="Refresh"
        refreshButtonOnClick={onRefresh}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          columns={columns}
          data={allOrders || []}
          onSort={onSort}
          sortBy={sorting}
          onPageChange={onPageChange}
          rowsPerPage={ordersPagination.pageSize}
          pageNumber={ordersPagination.pageNumber}
          totalRows={ordersPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Orders;
