import { categoryReduxConstant } from "../../constants";
import { successReducerType } from "../../shared/utility";

const {
  GET_ALL_CATEGORIES,
  GET_CATEGORY_DETAIL,
  GET_SUB_CATEGORIES,
  RESET_SUB_CATEGORIES,
} = categoryReduxConstant;

const initialState = {
  allCategories: [],
  subCategories: [],
  categoryDetail: {},
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_CATEGORIES):
      return {
        ...state,
        allCategories: action.payload.categories,
      };
    case successReducerType(GET_CATEGORY_DETAIL):
      return {
        ...state,
        allCategories: action.payload,
      };
    case successReducerType(GET_SUB_CATEGORIES):
      return {
        ...state,
        subCategories: action.payload,
      };
    case successReducerType(RESET_SUB_CATEGORIES):
      return {
        ...state,
        subCategories: [],
      };

    default:
      return state;
  }
};
