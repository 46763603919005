import React, { useEffect, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Paginator from "./pagination";

const ReactTable = ({
  columns,
  data,
  onSort,
  sortBy,
  totalRows,
  pageNumber,
  rowsPerPage,
  onPageChange,
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: pageNumber - 1, // Convert to 0-based index
    pageSize: rowsPerPage,
  });

  // Update pagination state when props change
  useEffect(() => {
    setPagination({
      pageIndex: pageNumber - 1, // Convert to 0-based index
      pageSize: rowsPerPage,
    });
  }, [pageNumber, rowsPerPage]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: onSort,
    state: {
      sorting: sortBy,
      pagination: pagination,
    },
    manualPagination: true, // Enable manual pagination handling
    pageCount: Math.ceil(totalRows / rowsPerPage), // Ensure correct page count
  });

  const handlePageChange = (page) => {
    onPageChange(page);
    setPagination({ ...pagination, pageIndex: page - 1 }); // Update local state with the new page
  };

  return (
    <>
      <Table hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const size = header.column.columnDef.size;
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: size || 200 }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "cursor-pointer select-none px-0"
                            : ""
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <FontAwesomeIcon
                              size="xs"
                              icon={faChevronUp}
                              className="ms-2 my-0 py-0"
                            />
                          ),
                          desc: (
                            <FontAwesomeIcon
                              size="xs"
                              icon={faChevronDown}
                              className="ms-2 my-0 py-0"
                            />
                          ),
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {data.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="table-cell p-2">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center p-2">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Paginator
        table={table}
        currentRows={data.length}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
        currentPage={pageNumber}
        rowCount={pagination.pageSize}
      />
    </>
  );
};

export default ReactTable;
