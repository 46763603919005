import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Image, Spinner } from "react-bootstrap";

import { userActions } from "../../redux/actions";
import logo from "../../assets/images/CIWUW-lg.png";
import { usersReduxConstants } from "../../constants";

// Define validation schema
const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Use Strong Password"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  // Get the combined token value from the URL
  const combinedToken = searchParams.get("token");

  // Initialize variables to store token and userId separately
  let token = "";
  let userId = "";

  if (combinedToken) {
    // Split the combined token to extract the real token and userId
    const tokenParts = combinedToken.split("?userId=");

    // Assign the first part as token and second part as userId
    token = tokenParts[0];
    userId = tokenParts[1] || ""; // In case userId is not found
  }

  const dispatch = useDispatch();
  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const navigate = useNavigate();

  const isSpinnerRunning = () => {
    return spinnerActions.includes(usersReduxConstants.FORGOT_PASSWORD);
  };
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    disabled: isSpinnerRunning(),
  });

  const onSubmit = (data) => {
    dispatch(
      userActions.resetPassword(
        { userId, token, newPassword: data.password },
        () => {
          reset();
          navigate("/login", { replace: true });
        }
      )
    );
  };

  return (
    <Container
      fluid
      className="w-full h-screen flex justify-center items-center"
    >
      <Container className="bg-slate-100 px-4 md:py-14 py-5 my-auto space-y-5 md:w-2/5 w-full rounded-xl shadow-md ">
        <div className="flex justify-center">
          <Image src={logo} width={200} height={"auto"} />
        </div>
        <h1 className="text-2xl font-semibold text-center">CHANGE PASSWORD</h1>

        <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <Form.Group>
            <Form.Label className="mb-0">New Password</Form.Label>
            <Form.Control
              type="password"
              isInvalid={errors.password}
              className="h-12 rounded-xl"
              placeholder="enter new password"
              {...register("password", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password && errors.password.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              className="h-12 rounded-xl"
              placeholder="re-type new password"
              isInvalid={errors.confirmPassword}
              {...register("confirmPassword", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && errors.confirmPassword.message}
            </Form.Control.Feedback>
          </Form.Group>

          <button
            type="submit"
            disabled={isSpinnerRunning()}
            className="h-14 w-full rounded-xl system-bg-color text-white font-semibold mt-5"
          >
            Submit {isSpinnerRunning() && <Spinner size="sm" />}
          </button>
        </Form>
      </Container>
    </Container>
  );
};

export default ForgotPassword;
