import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { CompactPicker } from "react-color";
import chroma from "chroma-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Custom component to render each option in the dropdown with a color swatch
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            width: 20,
            height: 20,
            backgroundColor: props.data.color,
            marginRight: 10,
            borderRadius: 4,
          }}
        ></span>
        {props.label}
      </div>
    </components.Option>
  );
};

// Custom component to render each selected option with a color swatch and a remove icon
const CustomMultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <span
          style={{
            width: 10,
            height: 10,
            backgroundColor: props.data.color,
            marginRight: 5,
            borderRadius: "50%",
          }}
        ></span>
        {props.children}
        <FontAwesomeIcon
          icon={faTimes}
          style={{ cursor: "pointer", marginLeft: 5 }}
          onClick={(e) => {
            e.stopPropagation();
            props.removeProps.onClick();
          }}
        />
      </div>
    </components.MultiValue>
  );
};

const ColorSelector = ({ errors, control }) => {
  const [selectedColors, setSelectedColors] = useState([]);

  // Update colourStyles to improve visibility of selected options
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = data.color;

      // Ensure color is valid and handle if it's a comma-separated string
      if (color.includes(",")) {
        // Pick the first color if there are multiple
        color = color.split(",")[0];
      }

      // Check if the color is valid
      if (!chroma.valid(color)) {
        color = "#000000"; // Default to black if invalid
      }

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? chroma(color).alpha(0.2).css() // Lighten the background color when selected
          : isFocused
          ? chroma(color).alpha(0.1).css() // Lighten the background color when focused
          : undefined,
        color: isSelected ? "black" : color, // Ensure selected text is readable
      };
    },
    multiValue: (styles, { data }) => {
      let color = data.color;

      // Ensure color is valid and handle if it's a comma-separated string
      if (color.includes(",")) {
        color = color.split(",")[0]; // Pick the first color
      }

      // Check if the color is valid
      if (!chroma.valid(color)) {
        color = "#000000"; // Default to black if invalid
      }

      return {
        ...styles,
        backgroundColor: chroma(color).alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => {
      let color = data.color;

      // Handle multiple colors and ensure it's a valid color string
      if (color.includes(",")) {
        color = color.split(",")[0];
      }

      // Check if color is valid
      if (!chroma.valid(color)) {
        color = "#000000"; // Default to black if invalid
      }

      return {
        ...styles,
        color: color,
      };
    },
    multiValueRemove: (styles, { data }) => {
      let color = data.color;

      // Handle multiple colors and ensure it's a valid color string
      if (color.includes(",")) {
        color = color.split(",")[0];
      }

      // Check if color is valid
      if (!chroma.valid(color)) {
        color = "#000000"; // Default to black if invalid
      }

      return {
        ...styles,
        color: color,
        ":hover": {
          backgroundColor: color,
          color: "white",
        },
      };
    },
  };

  const handleAddColor = (color) => {
    const newColor = {
      value: color.hex,
      label: color.hex,
      color: color.hex,
    };
    if (!selectedColors.some((c) => c.value === newColor.value)) {
      setSelectedColors([...selectedColors, newColor]);
    }
  };

  const handleRemoveColor = (colorValue) => {
    setSelectedColors(
      selectedColors.filter((color) => color.value !== colorValue)
    );
  };

  return (
    <>
      <Controller
        name="colors"
        control={control}
        defaultValue={[]} // No default colors
        rules={{ required: "At least one color must be selected" }}
        render={({ field }) => (
          <Select
            {...field}
            id="colors"
            isMulti
            placeholder="Select or add color"
            options={selectedColors} // Only display dynamically added colors
            onChange={(selectedOptions) => {
              field.onChange(selectedOptions);
            }}
            hideSelectedOptions={false}
            styles={colourStyles}
            isSearchable
            maxMenuHeight={180}
            classNamePrefix="multi-color-dropdown"
            components={{
              Option: CustomOption,
              MultiValue: CustomMultiValue,
            }}
          />
        )}
      />
      {errors.colors && (
        <small className="text-red-500">{errors.colors.message}</small>
      )}

      {/* Color Picker */}
      <div className="mt-3">
        <CompactPicker onChangeComplete={handleAddColor} />
      </div>

      {/* Display Selected Colors */}
      <div className="mt-3 d-flex flex-wrap">
        {selectedColors.map((color) => (
          <div
            key={color.value}
            style={{
              backgroundColor: color.color,
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            {color.label}
            <FontAwesomeIcon
              icon={faTimes}
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={() => handleRemoveColor(color.value)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ColorSelector;
