import { toast } from "react-toastify";

import api from "../../../system/api";
import { failureAction, successAction } from "../../../shared/utility";
import { systemReduxContant, usersReduxConstants } from "../../../constants";

const {
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_ALL_USERS_AS_ADMIN,
} = usersReduxConstants;

const getAllUsersAsAdmin = (params) => async (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_USERS_AS_ADMIN)
  );
  api
    .get(`/user/getAllAdmins`, {
      params: params,
    })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_USERS_AS_ADMIN, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_USERS_AS_ADMIN, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(
          systemReduxContant.SPINNER_DEACTIVATE,
          GET_ALL_USERS_AS_ADMIN
        )
      );
    });
};

const addNewUser = (body, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, ADD_USER));
  api
    .post(`/user/addUserByAdmin`, body)
    .then(
      (response) => {
        dispatch(successAction(ADD_USER, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllUsersAsAdmin(params));
        toast.success("Added Successfully");
      },
      (error) => {
        dispatch(failureAction(ADD_USER, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(successAction(systemReduxContant.SPINNER_DEACTIVATE, ADD_USER));
    });
};

const updateUser =
  (userId, body, params, funcExecuteOnSuccess) => (dispatch) => {
    dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_USER));
    api
      .put(`/user/updateUser/${userId}`, body)
      .then(
        (response) => {
          dispatch(successAction(UPDATE_USER, response));
          funcExecuteOnSuccess && funcExecuteOnSuccess();
          dispatch(getAllUsersAsAdmin(params));
          toast.success("Updated Successfully");
        },
        (error) => {
          dispatch(failureAction(UPDATE_USER, error));
          toast.error(error.message);
        }
      )
      .finally(() => {
        dispatch(
          successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_USER)
        );
      });
  };

const deleteUser = (userId, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, DELETE_USER));
  api
    .delete(`/user/deleteUser/${userId}`)
    .then(
      (response) => {
        dispatch(successAction(DELETE_USER, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllUsersAsAdmin(params));
        toast.success("Deleted Successfully");
      },
      (error) => {
        dispatch(failureAction(DELETE_USER, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, DELETE_USER)
      );
    });
};

const changePassword = (data, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, CHANGE_PASSWORD));
  api
    .post(`/user/resetPassword`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(
      (response) => {
        dispatch(successAction(CHANGE_PASSWORD, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllUsersAsAdmin(params));
        toast.success("Updated Successfully");
      },
      (error) => {
        dispatch(failureAction(CHANGE_PASSWORD, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, CHANGE_PASSWORD)
      );
    });
};

const forgotPassword = (email, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, FORGOT_PASSWORD));
  api
    .post(`/user/forgotPassword`, { email })
    .then(
      (response) => {
        dispatch(successAction(FORGOT_PASSWORD, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        toast.success(
          `An email has been sent to ${email}, Kindly check your email address.`
        );
      },
      (error) => {
        dispatch(failureAction(FORGOT_PASSWORD, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, FORGOT_PASSWORD)
      );
    });
};

const resetPassword = (data, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, FORGOT_PASSWORD));
  api
    .post(`/user/changePassword`, data)
    .then(
      (response) => {
        dispatch(successAction(FORGOT_PASSWORD, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
      },
      (error) => {
        dispatch(failureAction(FORGOT_PASSWORD, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, FORGOT_PASSWORD)
      );
    });
};

export const userActions = {
  addNewUser,
  updateUser,
  deleteUser,
  resetPassword,
  forgotPassword,
  changePassword,
  getAllUsersAsAdmin,
};
