import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactTable from "../../shared/components/reactTable/reactTable";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions, productsActions } from "../../redux/actions";
import StarRating from "../../shared/components/starRating/starRating";
import CustomeDateFilter from "../../shared/components/dateFilter/dateFilter";
import { formateCurreny } from "../../shared/utility";

const Dashboard = () => {
  const dispatch = useDispatch();
  const allTopRatedProducts = useSelector(
    (state) => state.productsReducer.allTopRatedProducts
  );
  const {
    googleAnalycticsData,
    dashboardStats: { previousDataResult, filteredResult },
  } = useSelector((state) => state.dashboard);
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - 6);

  const [dateState, setDateState] = useState({
    startDate: startDate,
    endDate: currentDate,
    selectedFilterOption: {
      name: "Last 7 days",
      visible: true,
      type: "relativePreset",
      localIdentifier: "LAST_7_DAYS",
    },
    excludeCurrentPeriod: false,
  });
  const [sorting, setSorting] = useState([{ id: "soldCount", desc: true }]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "productName",
        header: () => "Product Name",
      },
      {
        accessorKey: "size",
        header: () => "Size",
        cell: ({
          row: {
            original: { sizes },
          },
        }) => {
          return sizes.toString();
        },
      },
      {
        accessorKey: "color",
        header: "Color",
        cell: ({
          row: {
            original: { colors },
          },
        }) => {
          return (
            <div className="flex gap-1 items-center">
              {colors.map((color) => (
                <div className="flex items-center flex-wrap">
                  <span
                    className="p-2 rounded-full m-0.5"
                    style={{ backgroundColor: color }}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "price",
        header: "Price",
        cell: (row) => <span>£{row.getValue()}</span>,
      },
      {
        accessorKey: "brand",
        header: "Brand",
      },
      {
        accessorKey: "category",
        header: () => "Category",
      },
      {
        accessorKey: "averageRating",
        header: () => "Rating",
        cell: (row) => <StarRating rating={row.getValue()} />,
      },
      {
        accessorKey: "soldCount",
        header: () => "Sold Items Count",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const calculateTrend = (current, previous) => {
    if (previous === 0) {
      return current > 0 ? 100 : 0; // 100% growth if current > 0, else 0%
    } else {
      return ((current - previous) / previous) * 100;
    }
  };

  const trends = {
    customerTrend: calculateTrend(
      filteredResult.totalCustomers,
      previousDataResult.totalCustomers
    ),
    revenueTrend: calculateTrend(
      filteredResult.totalRevenue,
      previousDataResult.totalRevenue
    ),
    soldTrend: calculateTrend(
      filteredResult.totalSold,
      previousDataResult.totalSold
    ),
  };

  const getConversionRate = (totalUsers, registeredUsers) => {
    if (totalUsers === 0) {
      return 0; // To avoid division by zero
    }
    return (registeredUsers / totalUsers) * 100;
  };

  useEffect(() => {
    dispatch(productsActions.getAllTopRatedProducts());

    dispatch(
      dashboardActions.getGoogleAnalytics({
        startDate: dateState.startDate,
        endDate: dateState.endDate,
      })
    );
    dispatch(
      dashboardActions.getDashboardStats({
        startDate: dateState.startDate,
        endDate: dateState.endDate,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState.startDate, dateState.endDate]);

  return (
    <Container fluid className="py-3">
      <h1 className="text-2xl font-semibold">Dashboard</h1>
      <div>
        <CustomeDateFilter dateState={dateState} setDateState={setDateState} />
      </div>
      <Row className="g-2 pt-3 flex items-stretch">
        <Col md={3}>
          <div className="bg-slate-100 space-y-3 p-4 rounded d-flex flex-column justify-content-between h-100">
            <h2 className="text-slate-500">Users</h2>
            <div>
              <h1 className="font-semibold text-3xl">
                {googleAnalycticsData.totalUsers || 0}
              </h1>
            </div>
            <p>
              <small>
                {googleAnalycticsData.userIncreasePercentage && (
                  <span
                    className={`${
                      googleAnalycticsData.userIncreasePercentage.includes("-")
                        ? "text-red-400"
                        : "text-green-400"
                    } font-bold pe-2`}
                  >
                    {googleAnalycticsData.userIncreasePercentage}
                  </span>
                )}

                <span className="text-slate-500">
                  {dateState.selectedFilterOption.name}
                </span>
              </small>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="bg-slate-100 space-y-3 p-4 rounded d-flex flex-column justify-content-between h-100">
            <h2 className="text-slate-500">Sales</h2>
            <div>
              <h1 className="font-semibold text-3xl">
                {filteredResult.totalSold}
              </h1>
              <p className="text-slate-500">Pieces</p>
            </div>
            <p>
              <small>
                <span
                  className={`${
                    trends.soldTrend > 0
                      ? "text-green-500"
                      : trends.soldTrend === 0
                      ? "text-slate-500"
                      : "text-red-500"
                  } font-bold`}
                >
                  {Math.abs(trends.soldTrend).toFixed(2)}%{" "}
                </span>
                <span className="text-slate-500">
                  {dateState.selectedFilterOption.name}
                </span>
              </small>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="bg-slate-100 space-y-3 p-4 rounded d-flex flex-column justify-content-between h-100">
            <h2 className="text-slate-500">Customers</h2>
            <div>
              <h1 className="font-semibold text-3xl">
                {filteredResult.totalCustomers}
              </h1>
              <p className="text-slate-500">Customers</p>
            </div>
            <p>
              <small>
                <span
                  className={`${
                    trends.customerTrend > 0
                      ? "text-green-500"
                      : trends.customerTrend === 0
                      ? "text-slate-500"
                      : "text-red-500"
                  } font-bold`}
                >
                  {Math.abs(trends.customerTrend).toFixed(2)}%{" "}
                </span>
                <span className="text-slate-500">
                  {dateState.selectedFilterOption.name}
                </span>
              </small>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="bg-slate-100 space-y-3 p-4 rounded d-flex flex-column justify-content-between h-100">
            <h2 className="text-slate-500">Revenue</h2>
            <div>
              <h1 className="font-semibold text-3xl">
                £{formateCurreny(filteredResult.totalRevenue / 100)}
              </h1>
              <p className="text-slate-500">UK Pounds</p>
            </div>
            <p>
              <small>
                <span
                  className={`${
                    trends.revenueTrend > 0
                      ? "text-green-500"
                      : trends.revenueTrend === 0
                      ? "text-slate-500"
                      : "text-red-500"
                  } font-bold`}
                >
                  {Math.abs(trends.revenueTrend).toFixed(2)}%{" "}
                </span>
                <span className="text-slate-500">
                  {dateState.selectedFilterOption.name}
                </span>
              </small>
            </p>
          </div>
        </Col>
        <Col md={6} className="d-flex flex-column h-100">
          <div className="bg-slate-100 space-y-3 p-4 rounded d-flex justify-content-between align-items-center mt-1 me-md-1 h-100">
            <div>
              <p>Customer Conversion Rate</p>
              <h1 className="font-semibold text-2xl">
                {getConversionRate(
                  googleAnalycticsData.totalUsers,
                  filteredResult.totalCustomers
                ).toFixed(2)}
                %
              </h1>
            </div>
          </div>
        </Col>
        <Col md={6} className="d-flex flex-column h-100">
          <div className="bg-slate-100 space-y-1 p-4 rounded mt-1 h-100">
            <div>
              <h2>Average Engagement Time</h2>
              <div className="flex items-end">
                <h1 className="font-semibold text-3xl">
                  {googleAnalycticsData.avgEngagementTimePerActiveUser || 0}{" "}
                </h1>
                <small className="ms-2">(Per User)</small>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="g-2 pt-3 flex items-stretch">
        <Col xs={12} className="d-flex flex-column">
          <div className="bg-slate-100 rounded mt-md-0 mt-2 d-flex flex-column flex-grow-1 pb-3">
            <h2 className="p-2 text-lg font-semibold">Browser used by Users</h2>
            <Row className="h-100 px-3 g-2">
              {googleAnalycticsData.browsers &&
              googleAnalycticsData.browsers.length > 0 ? (
                googleAnalycticsData.browsers.map((browser) => (
                  <Col md={2}>
                    <div className="bg-white p-2 rounded d-flex flex-column justify-content-between h-20">
                      <div>
                        <h2 className="font-extrabold">{browser.browser}</h2>
                      </div>

                      <div className="flex items-center">
                        <h2 className="font-extrabold pe-1">{browser.users}</h2>
                        <small>Users</small>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="flex items-center justify-center">
                  No Data Found
                </div>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="g-2 pt-3 flex items-stretch">
        <Col xs={12} className="d-flex flex-column">
          <div className="bg-slate-100 rounded mt-md-0 mt-2 d-flex flex-column flex-grow-1 pb-3">
            <h2 className="p-2 text-lg font-semibold">User By Country</h2>
            <Row className="h-100 px-3 g-2">
              {googleAnalycticsData.countries &&
              googleAnalycticsData.countries.length > 0 ? (
                googleAnalycticsData.countries.map((country) => (
                  <Col md={2}>
                    <div className="bg-white p-2 rounded d-flex flex-column justify-content-between h-20">
                      <div>
                        <h2 className="font-extrabold">{country.country}</h2>
                      </div>

                      <div className="flex items-center">
                        <h2 className="font-extrabold pe-1">{country.users}</h2>
                        <small>Users</small>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="flex items-center justify-center">
                  No Data Found
                </div>
              )}
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="g-2 py-3 flex items-stretch">
        <Col xs={12} className="d-flex flex-column">
          <div className="bg-slate-100 rounded mt-md-0 mt-2 d-flex flex-column flex-grow-1">
            <h2 className="p-2 text-lg font-semibold">Views by Pages</h2>
            <Row className="p-3 g-2">
              {googleAnalycticsData.pages &&
              googleAnalycticsData.pages.length > 0 ? (
                googleAnalycticsData.pages.map(
                  (page) =>
                    page.pageName.includes("CIWUW") && (
                      <Col md={4}>
                        <div className="bg-white p-2 rounded d-flex flex-column justify-content-between h-24">
                          <div>
                            <h2 className="font-extrabold">{page.pageName}</h2>
                            <p className="text-slate-500 text-small">
                              {page.avgTimeSpent} Spent
                            </p>
                          </div>

                          <div className="flex items-center">
                            <h2 className="font-extrabold">
                              {page.totalViews}
                            </h2>{" "}
                            / <span>views</span>
                          </div>
                        </div>
                      </Col>
                    )
                )
              ) : (
                <div className="flex items-center justify-center">
                  No Data Found
                </div>
              )}
            </Row>
          </div>
        </Col>
      </Row>

      <div className="h-full shadow p-3 rounded">
        <ReactTable
          bgColor="bg-slate-100"
          columns={columns}
          data={allTopRatedProducts || []}
          onSort={onSort}
          sortBy={sorting}
          rowsPerPage={allTopRatedProducts.length || 0}
          totalRows={allTopRatedProducts.length || 0}
        />
      </div>
    </Container>
  );
};

export default Dashboard;
