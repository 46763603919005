export const dateFilterGranularityConstants = {
  HOUR: "GDC.time.hour",
  DATE: "GDC.time.date",
  YEAR: "GDC.time.year",
  MONTH: "GDC.time.month",
  WEEK: "GDC.time.week_us",
  MINUTE: "GDC.time.minute",
  QUARTER: "GDC.time.quarter",
};

export const dateFilterOptionConstants = {
  ALL_TIME: "allTime",
  ABSOLUTE_FROM: "absoluteForm",
  RELATIVE_FROM: "relativeForm",
  ABSOLUTE_PRESET: "absolutePreset",
  RELATIVE_PRESET: "relativePreset",
};

export const localIdentifierConstants = {
  ALL_TIME: "ALL_TIME",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  LAST_7_DAYS: "LAST_7_DAYS",
  THIS_QUARTER: "THIS_QUARTER",
  LAST_QUARTER: "LAST_QUARTER",
  LAST_90_DAYS: "LAST_90_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  ABSOLUTE_FORM: "ABSOLUTE_FORM",
  LAST_12_MONTHS: "LAST_12_MONTHS",
  LAST_4_QUARTERS: "LAST_4_QUARTERS",
};

//Default date format
export const DEFAULT_DATE_FORMAT = "YYYY/MM/D";

//Default date filter mode active, readonly
export const DATE_FILTER_MODE = "active";

const { DATE, MONTH, QUARTER, YEAR } = dateFilterGranularityConstants;
const { ALL_TIME, ABSOLUTE_FROM, RELATIVE_PRESET } = dateFilterOptionConstants;

export const availableGranularities = [DATE, MONTH, QUARTER, YEAR];

export const defaultDateFilterOptions = {
  allTime: {
    name: "All time",
    visible: true,
    type: ALL_TIME,
    localIdentifier: localIdentifierConstants.ALL_TIME,
  },
  absoluteForm: {
    to: new Date().toISOString().slice(0, 10), // 'YYYY-MM-DD'
    from: new Date().toISOString().slice(0, 10), // 'YYYY-MM-DD'
    name: "Static",
    visible: true,
    type: ABSOLUTE_FROM,
    localIdentifier: localIdentifierConstants.ABSOLUTE_FORM,
  },
  relativePreset: {
    "GDC.time.year": [
      {
        to: 0,
        from: 0,
        name: "This year",
        visible: true,
        granularity: YEAR,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.THIS_YEAR,
      },
      {
        to: -1,
        from: -1,
        name: "Last year",
        visible: true,
        granularity: YEAR,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_YEAR,
      },
    ],
    "GDC.time.quarter": [
      {
        to: 0,
        from: 0,
        name: "This quarter",
        visible: true,
        granularity: QUARTER,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.THIS_QUARTER,
      },
      {
        to: -1,
        from: -1,
        name: "Last quarter",
        visible: true,
        granularity: QUARTER,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_QUARTER,
      },
      {
        to: 0,
        from: -3,
        name: "Last 4 quarters",
        visible: true,
        granularity: QUARTER,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_4_QUARTERS,
      },
    ],
    "GDC.time.month": [
      {
        to: 0,
        from: 0,
        name: "This month",
        visible: true,
        granularity: MONTH,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.THIS_MONTH,
      },
      {
        to: -1,
        from: -1,
        name: "Last month",
        visible: true,
        granularity: MONTH,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_MONTH,
      },
      {
        to: 0,
        from: -11,
        name: "Last 12 months",
        visible: true,
        granularity: MONTH,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_12_MONTHS,
      },
    ],
    "GDC.time.date": [
      {
        to: 0,
        from: 0,
        name: "Today",
        visible: true,
        granularity: DATE,
        type: RELATIVE_PRESET,
        localIdentifier: "TODAY",
      },
      {
        to: -1,
        from: -1,
        name: "Yesterday",
        visible: true,
        granularity: DATE,
        type: RELATIVE_PRESET,
        localIdentifier: "YESTERDAY",
      },
      {
        to: 0,
        from: -6,
        name: "Last 7 days",
        visible: true,
        granularity: DATE,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_7_DAYS,
      },
      {
        to: 0,
        from: -29,
        name: "Last 30 days",
        visible: true,
        granularity: DATE,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_30_DAYS,
      },
      {
        to: 0,
        from: -89,
        name: "Last 90 days",
        visible: true,
        granularity: DATE,
        type: RELATIVE_PRESET,
        localIdentifier: localIdentifierConstants.LAST_90_DAYS,
      },
    ],
  },
};
