export const usersReduxConstants = {
  ADD_USER: "ADD_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  GET_ALL_USERS_AS_ADMIN: "GET_ALL_USERS_AS_ADMIN",
};

export const userPageFilters = [
  { name: "All", value: "" },
  {
    name: "Active",
    value: "active",
  },
  {
    name: "In-Active",
    value: "inActive",
  },
];
