import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

const StarRating = ({ rating }) => {
  //   const [hoverRating, setHoverRating] = useState(0);

  //   const handleMouseEnter = (index) => {
  //     setHoverRating(index);
  //   };

  //   const handleMouseLeave = () => {
  //     setHoverRating(0);
  //   };

  //   const handleClick = (index) => {
  //     onRatingChange(index);
  //   };

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((index) => (
        <FontAwesomeIcon
          key={index}
          icon={index <= rating ? solidStar : regularStar}
          className={`text-yellow-500`}
          //   onMouseEnter={() => handleMouseEnter(index)}
          //   onMouseLeave={handleMouseLeave}
          //   onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default StarRating;
