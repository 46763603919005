import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../shared/components/header/header";
import { convertToReadableDate } from "../../shared/utility";
import ReactTable from "../../shared/components/reactTable/reactTable";
import StarRating from "../../shared/components/starRating/starRating";
import { ratingActions } from "../../redux/actions/rating.actions";
import { openConfirmationModal, viewCustomerDetailsModal } from "../../shared";
import useModalDisplay from "../../shared/hooks/useModalDisplay";

const Reviews = () => {
  const dispatch = useDispatch();
  const { allRatings, ratingPagination } = useSelector(
    (state) => state.ratingReducer
  );

  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "updatedAt", desc: true }]);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const columns = useMemo(
    () => [
      {
        accessorKey: "userDetails.username",
        header: () => "Customer",
        cell: ({ row: { original } }) => {
          return (
            <span
              role="button"
              className="text-blue-500"
              onClick={() => {
                setComponent(
                  viewCustomerDetailsModal({
                    title: "Customer Details",
                    onClose: closeModal,
                    data: original.userDetails,
                  })
                );
              }}
            >
              {original?.userDetails?.username}
            </span>
          );
        },
      },
      {
        accessorKey: "product.name",
        header: () => "Product",
      },
      {
        accessorKey: "product.size",
        header: () => "Size",
      },
      {
        accessorKey: "content",
        header: () => "Review",
        cell: (row) => (
          <div className="truncate-text m-0 p-0">
            <p className="p-0 m-0" title={row.getValue()}>
              {row.getValue()}
            </p>
          </div>
        ),
      },
      {
        accessorKey: "product.price",
        header: () => "Price",
        cell: (row) => `£${row.getValue() / 100}`,
      },
      {
        accessorKey: "updatedAt",
        header: () => "Delivered Date",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "noOfStars",
        header: () => "Rating",
        cell: (row) => <StarRating rating={row.getValue()} />,
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue === "published" ? (
                <span className="text-green-600">Published</span>
              ) : columnValue === "under-review" ? (
                <span className="text-yellow-600">Under Review</span>
              ) : (
                <span className="text-red-600 ">Not Accepted</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "Actions",
        cell: ({ row: { original } }) => {
          return (
            <>
              <FontAwesomeIcon
                icon={faCircleCheck}
                type="button"
                color="green"
                className="mx-2"
                onClick={() => {
                  setComponent(
                    openConfirmationModal({
                      title: "Confirmation Approving Review",
                      onClose: closeModal,
                      description: `Are you sure you want to approve this Review'?`,
                      onConfirm: () => {
                        dispatch(
                          ratingActions.updateRating(
                            original._id,
                            { status: "published" },
                            () => {
                              closeModal();
                              getData(ratingPagination.pageNumber);
                            }
                          )
                        );
                      },
                      closeModal,
                    })
                  );
                }}
              />
              <FontAwesomeIcon
                color="red"
                type="button"
                icon={faTimesCircle}
                onClick={() => {
                  setComponent(
                    openConfirmationModal({
                      title: "Confirmation Rejecting Review",
                      onClose: closeModal,
                      description: `Are you sure you want to reject this Review'?`,
                      onConfirm: () => {
                        dispatch(
                          ratingActions.updateRating(
                            original._id,
                            { status: "not-accepted" },
                            () => {
                              closeModal();
                              getData(ratingPagination.pageNumber);
                            }
                          )
                        );
                      },
                      closeModal,
                    })
                  );
                }}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const getData = (pageNo = 1) => {
    dispatch(
      ratingActions.getAllRating({
        page: pageNo,
        limit: 10,
        searchQuery: search,
        rating: filter.toString(),
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const onRefresh = () => {
    getData(1);
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting[0]?.desc, sorting[0]?.id, filter]);

  return (
    <Container fluid>
      {showModal && modalElement}
      <Header
        title="Reviews"
        search={search}
        filter={filter}
        setOnSearch={setSearch}
        filters={[
          { name: "All", value: "" },
          { name: "1 Star", value: "1" },
          { name: "2 Star", value: "2" },
          { name: "3 Star", value: "3" },
          { name: "4 Star", value: "4" },
          { name: "5 Star", value: "5" },
        ]}
        setFilter={onFilterChange}
        refreshButtonName="Refresh"
        refreshButtonOnClick={onRefresh}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          columns={columns}
          data={allRatings || []}
          onSort={onSort}
          sortBy={sorting}
          onPageChange={onPageChange}
          rowsPerPage={ratingPagination.pageSize}
          pageNumber={ratingPagination.pageNumber}
          totalRows={ratingPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Reviews;
