import { toast } from "react-toastify";
import { categoryReduxConstant, systemReduxContant } from "../../constants";
import { failureAction, successAction } from "../../shared/utility";
import api from "../../system/api";

const {
  GET_ALL_CATEGORIES,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  GET_SUB_CATEGORIES,
  RESET_SUB_CATEGORIES,
} = categoryReduxConstant;

const getAllCategories = (params) => (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_CATEGORIES)
  );
  api
    .get("/category/getAll", { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_CATEGORIES, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_CATEGORIES, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_CATEGORIES)
      );
    });
};

const updateCategory = (data, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_CATEGORY));
  api
    .put(`/category/updateCategory/${data._id}`, data)
    .then(
      (response) => {
        dispatch(successAction(UPDATE_CATEGORY, response));
        funcExecuteOnSuccess();
        dispatch(getAllCategories());
      },
      (error) => {
        dispatch(failureAction(UPDATE_CATEGORY, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_CATEGORY)
      );
    });
};

const createCategory = (data, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, CREATE_CATEGORY));
  api
    .post("/category/create", data)
    .then(
      (response) => {
        dispatch(successAction(CREATE_CATEGORY, response));
        funcExecuteOnSuccess();
        dispatch(getAllCategories());
      },
      (error) => {
        dispatch(failureAction(CREATE_CATEGORY, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, CREATE_CATEGORY)
      );
    });
};

const deleteCategory = (id, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, DELETE_CATEGORY));
  api
    .delete(`/category/deleteCategory/${id}`)
    .then(
      (response) => {
        dispatch(successAction(DELETE_CATEGORY, response));
        funcExecuteOnSuccess();
        dispatch(getAllCategories());
      },
      (error) => {
        dispatch(failureAction(DELETE_CATEGORY, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, DELETE_CATEGORY)
      );
    });
};

const getSubCategoriesByCategoryId =
  (id, funcExecuteOnSuccess) => (dispatch) => {
    dispatch(
      successAction(systemReduxContant.SPINNER_ACTIVATE, GET_SUB_CATEGORIES)
    );
    api
      .get(`/category/subcategories/${id}`)
      .then(
        (response) => {
          dispatch(successAction(GET_SUB_CATEGORIES, response));
          funcExecuteOnSuccess && funcExecuteOnSuccess();
        },
        (error) => {
          dispatch(failureAction(GET_SUB_CATEGORIES, error));
          toast.error(error.message);
        }
      )
      .finally(() => {
        dispatch(
          successAction(
            systemReduxContant.SPINNER_DEACTIVATE,
            GET_SUB_CATEGORIES
          )
        );
      });
  };

const searchCategory = (searchedValue) => (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_CATEGORIES)
  );
  api
    .get(`/category/search?query=${searchedValue}`)
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_CATEGORIES, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_CATEGORIES, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_CATEGORIES)
      );
    });
};

const resetSubCategories = () => (dispatch) => {
  dispatch(successAction([], RESET_SUB_CATEGORIES));
};

export const categoriesActions = {
  searchCategory,
  createCategory,
  updateCategory,
  deleteCategory,
  getAllCategories,
  resetSubCategories,
  getSubCategoriesByCategoryId,
};
