import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";

// Define validation schema
const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("New password is required"),
  repeatNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Repeat new password is required"),
});

const ChangeUserPassword = ({ title, closeModal, onConfirm }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className="font-bold">{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="space-y-3">
            <Form.Group>
              <Form.Control
                type="password"
                name="newPassword"
                className="h-12 rounded-md"
                placeholder="New Password"
                {...register("newPassword")}
                isInvalid={errors.newPassword}
              />
              {errors.newPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                name="repeatNewPassword"
                className="h-12 rounded-md"
                placeholder="Repeat New Password"
                {...register("repeatNewPassword")}
                isInvalid={errors.repeatNewPassword}
              />
              {errors.repeatNewPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.repeatNewPassword.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              onClick={closeModal}
              type="button"
              className="border-2 border-red-600 w-full h-12 rounded text-red-600 hover:text-white hover:bg-red-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#29D30A] w-full h-12 text-white rounded"
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeUserPassword;
