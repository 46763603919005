import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { categoriesActions } from "../../redux/actions";
import Header from "../../shared/components/header/header";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import { getCreateCategoryModal, openConfirmationModal } from "../../shared";

const Categories = () => {
  const dispatch = useDispatch();
  const { allCategories } = useSelector((state) => state.categoryReducer);

  const [search, setSearch] = useState("");
  const [activeKey, setActiveKey] = useState(null);
  const [activeSubKey, setActiveSubKey] = useState(null);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleSubToggle = (key) => {
    setActiveSubKey(activeSubKey === key ? null : key);
  };

  const onAdd = (isSubCategory = false, parentCategoryId, division) => {
    setComponent(
      getCreateCategoryModal({
        title: `Add ${isSubCategory ? "Sub" : "Main"} Category`,
        onClose: closeModal,
        isSubCategory,
        data: { division: division },
        onConfirm: (data, reset) => {
          dispatch(
            categoriesActions.createCategory(
              {
                ...data,
                ...(isSubCategory && {
                  parentCategory: parentCategoryId,
                  division: division,
                }),
              },
              () => {
                reset();
                closeModal();
              }
            )
          );
        },
      })
    );
  };

  const onEdit = (data, isSubCategory) => {
    setComponent(
      getCreateCategoryModal({
        data,
        title: "Edit Category",
        onClose: closeModal,
        isSubCategory,
        onConfirm: (dataToSend, reset) => {
          dispatch(
            categoriesActions.updateCategory(
              { _id: data._id, ...dataToSend },
              () => {
                reset();
                closeModal();
              }
            )
          );
        },
      })
    );
  };

  const onDelete = (data) => {
    setComponent(
      openConfirmationModal({
        title: "Confirmation Delete Category",
        onClose: closeModal,
        description: `Are you sure you want to delete category '${data.categoryName}'?`,
        onConfirm: () => {
          dispatch(
            categoriesActions.deleteCategory(data._id, () => {
              closeModal();
            })
          );
        },
        closeModal,
      })
    );
  };

  const onAccordionEntering = (categoryId) => {
    dispatch(categoriesActions.getSubCategoriesByCategoryId(categoryId));
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    dispatch(categoriesActions.getAllCategories({ searchQuery: search }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container fluid className="pb-3">
      {showModal && modalElement}
      <Header
        title="Categories"
        search={search}
        setOnSearch={onSearch}
        buttonName="+ Add"
        buttonOnClick={() => onAdd(false)}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        {allCategories.length > 0 ? (
          allCategories.map((category) => (
            <Accordion activeKey={activeKey}>
              <Accordion.Item eventKey={category._id}>
                <Accordion.Header onClick={() => handleToggle(category._id)}>
                  <div className="flex justify-between w-full">
                    <h4 className="font-semibold">{category.categoryName}</h4>

                    <div className="mx-2 flex gap-2 items-center">
                      <p
                        className={
                          category.isActive ? "text-green-600" : "text-red-600"
                        }
                      >
                        {category.isActive ? "Active" : "InActive"}
                      </p>
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEdit(category, false);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(category);
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  onEntering={() => onAccordionEntering(category._id)}
                  onExited={() =>
                    dispatch(categoriesActions.resetSubCategories())
                  }
                >
                  <div className="flex flex-col gap-3">
                    <div className="p-2 shadow-sm rounded-md">
                      <p className="font-semibold">Description:</p>
                      <p>{category.description}</p>
                    </div>
                    <div className="p-2 shadow-sm rounded-md">
                      <div className="flex items-center justify-between">
                        <p className="font-semibold">Sub Categories:</p>
                        <p
                          role="button"
                          className="text-green-700 font-bold"
                          onClick={() =>
                            onAdd(true, category._id, category.division)
                          }
                        >
                          + Add
                        </p>
                      </div>
                      {category.subcategories?.length > 0 ? (
                        category.subcategories.map((sCategory) => (
                          <Accordion
                            activeKey={activeSubKey}
                            key={sCategory._id}
                          >
                            <Accordion.Item eventKey={sCategory._id}>
                              <Accordion.Header
                                onClick={() => handleSubToggle(sCategory._id)}
                              >
                                <div className="flex justify-between w-full">
                                  <h4 className="font-semibold">
                                    {sCategory.categoryName}
                                  </h4>

                                  <div className="mx-2 flex gap-2 items-center">
                                    <p
                                      className={
                                        sCategory.isActive
                                          ? "text-green-600"
                                          : "text-red-600"
                                      }
                                    >
                                      {sCategory.isActive
                                        ? "Active"
                                        : "InActive"}
                                    </p>
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(sCategory, true);
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      color="red"
                                      className="cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete(sCategory, true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="p-2 shadow-sm rounded-md">
                                  <p className="font-semibold">Description:</p>
                                  <p>{sCategory.description}</p>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))
                      ) : (
                        <p className="mb-0 p-2 text-center">
                          No Sub Category found!
                        </p>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))
        ) : (
          <div className="flex items-center justify-center">
            No Category Found
          </div>
        )}
      </div>
    </Container>
  );
};

export default Categories;
