import { authReducer } from "./auth.reducer";
import systemReducer from "./system.reducer";
import { usersReducer } from "./users.reducer";
import { ordersReducer } from "./order.reducer";
import { productsReducer } from "./product.reducer";
import { categoryReducer } from "./categories.reducer";
import { returnsReducer } from "./returns.reducer";
import { customersReducer } from "./customers.reducer";
import { dashboard } from "./dashboard.reducer";
import { ratingReducer } from "./rating.reducer";
import { activityLogsReducer } from "./activityLogs.reducer";

export const allReducers = {
  dashboard,
  authReducer,
  usersReducer,
  systemReducer,
  ordersReducer,
  ratingReducer,
  returnsReducer,
  productsReducer,
  categoryReducer,
  customersReducer,
  activityLogsReducer,
};
