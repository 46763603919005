import React from "react";
import { useForm } from "react-hook-form";
import { Form, Image, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { orderReduxConstant } from "../../../constants";
import { Link } from "react-router-dom";

function UpdateOrderModal({ title, onClose, onConfirm, data }) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: data });

  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const isSpinnerRunning = () =>
    spinnerActions.includes(orderReduxConstant.UPDATE_ORDER);

  const onSubmit = (data) => {
    onConfirm(data);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3">
            <h4 className="font-semibold">Product Ordered:</h4>
            <div className="border rounded-md p-2 bg-slate-50 flex flex-col gap-2">
              {data.products.map((product) => (
                <div className="bg-white rounded-md p-2 shadow-md">
                  <div className="flex items-center justify-between">
                    <div>
                      <p>
                        <span className="font-semibold">Name:</span>
                        {product.name}
                      </p>
                      <div className="flex items-center">
                        <span className="font-semibold">Color:</span>
                        <div
                          className="h-4 w-4 rounded-full ms-2"
                          style={{ backgroundColor: product.color }}
                        ></div>
                      </div>
                      <p>
                        <span className="font-semibold">Size:</span>
                        {product.size}
                      </p>
                      <p>
                        <span className="font-semibold">Quantity:</span>
                        {product.quantity}
                      </p>
                      <p>
                        <span className="font-semibold">Price:</span>£
                        {product.price / 100}
                      </p>
                      <p>
                        <span className="font-semibold">Supplier Link: </span>
                        <Link
                          to={product.supplier_link}
                          target="_blank"
                          className="text-blue-400"
                        >
                          {product.supplier_link}
                        </Link>
                      </p>
                    </div>
                    <div>
                      <Image
                        src={product.image}
                        alt="product image"
                        width={100}
                        height={100}
                        className="rounded-md w-26 h-28"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Sub Total:</p>
              <p className="mb-0">£{data.totalPrice} </p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Delivery Charges:</p>
              <p className="mb-0">£{data?.deliveryCharges || "Free"} </p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Delivery Charges:</p>
              <p className="mb-0">
                £{(data.totalPrice + data?.deliveryCharges).toFixed(2)}{" "}
              </p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Ordered Date:</p>
              <p className="mb-0">{data.createdAt}</p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Email:</p>
              <p className="mb-0">{data.email}</p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <p className="mb-0 font-semibold">Shipping Address:</p>
              <p className="mb-0">{data.address}</p>
            </div>

            <Form.Group>
              <Form.Label>Status:</Form.Label>
              <Form.Select
                {...register("status", { required: true })}
                isInvalid={errors.status}
                className="h-12 rounded-md"
                disabled={isSpinnerRunning() || data.status === "refunded"}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="cancel">Cancel</option>
                <option value="completed">Completed</option>
                <option value="refunded">Refunded</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              type="button"
              disabled={isSpinnerRunning()}
              onClick={closeModal}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSpinnerRunning()}
              className="bg-[#29D30A] w-full h-12 text-white rounded"
            >
              Add {isSpinnerRunning() && <Spinner size="sm" />}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UpdateOrderModal;
