import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import Header from "../../shared/components/header/header";
import { activityLogsActions } from "../../redux/actions";
import ReactTable from "../../shared/components/reactTable/reactTable";
import { convertToReadableDateWithTime } from "../../shared/utility";

const currentDate = new Date();
const startDate = new Date(currentDate);
startDate.setDate(currentDate.getDate() - 6);

const ActivityLogs = () => {
  const dispatch = useDispatch();
  const { allActivityLogs, activityLogsPagination } = useSelector(
    (state) => state.activityLogsReducer
  );

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({});
  const [sorting, setSorting] = useState([{ id: "dateTime", desc: true }]);

  const [dateState, setDateState] = useState({
    startDate: startDate,
    endDate: currentDate,
    selectedFilterOption: {
      name: "Last 7 days",
      visible: true,
      type: "relativePreset",
      localIdentifier: "LAST_7_DAYS",
    },
    excludeCurrentPeriod: false,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "whom.username",
        header: () => "Name",
      },
      {
        accessorKey: "whom.role",
        header: () => "Role",
      },
      {
        accessorKey: "action",
        header: () => "Description",
      },
      {
        accessorKey: "logType",
        header: () => "type",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue === "Info" ? (
                <span className="text-green-600">{columnValue}</span>
              ) : (
                <span className="text-red-600 ">{columnValue}</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "dateTime",
        header: () => "Log Date Time",
        cell: (row) => convertToReadableDateWithTime(row.getValue()),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNo = 1, startDate, endDate) => {
    dispatch(
      activityLogsActions.getAllActivityLogs({
        page: pageNo,
        pageSize: 10,
        status: filter,
        searchQuery: search,
        startDate,
        endDate,
      })
    );
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo, dateState.startDate, dateState.endDate);
  };

  const onRefresh = () => {
    getData(1, dateState.startDate, dateState.endDate);
  };

  useEffect(() => {
    getData(1, dateState.startDate, dateState.endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, dateState.startDate, dateState.endDate]);

  return (
    <Container fluid className="pb-3">
      <Header
        search={search}
        filter={filter}
        dateState={dateState}
        setDateState={setDateState}
        setFilter={onFilterChange}
        title="Activity Logs"
        setOnSearch={setSearch}
        filters={[
          { name: "All", value: "" },
          { name: "Info", value: "info" },
          { name: "Error", value: "error" },
        ]}
        refreshButtonName="Refresh"
        refreshButtonOnClick={onRefresh}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          columns={columns}
          data={allActivityLogs}
          onSort={onSort}
          sortBy={sorting}
          onPageChange={onPageChange}
          rowsPerPage={activityLogsPagination.pageSize}
          pageNumber={activityLogsPagination.pageNumber}
          totalRows={activityLogsPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default ActivityLogs;
