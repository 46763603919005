import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { convertToReadableDate } from "../../utility";

const CustomerDetailsModal = ({ onClose, title, data }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-3 p-2">
          <Col xs={4} className="font-semibold">
            Customer Id:
          </Col>
          <Col xs={8}>{data?._id}</Col>
          <Col xs={4} className="font-semibold">
            Name:
          </Col>
          <Col xs={8}>{data.username}</Col>
          <Col xs={4} className="font-semibold">
            Email:
          </Col>
          <Col xs={8}>{data.email}</Col>
          <Col xs={4} className="font-semibold">
            Registration Date:
          </Col>
          <Col xs={8}>{convertToReadableDate(data.createdAt)}</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerDetailsModal;
