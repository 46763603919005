import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { usersReduxConstants } from "../../../constants";

const ForgotPasswordModal = ({ onClose, onConfirm }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const isSpinnerRunning = () => {
    return spinnerActions.includes(usersReduxConstants.FORGOT_PASSWORD);
  };

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-bold">Forgot Password</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter Your Email Address:</Form.Label>
            <Form.Control
              type="email"
              className="h-12"
              isInvalid={errors.email}
              placeholder="example@email.com"
              {...register("email", { required: true })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              type="button"
              onClick={closeModal}
              disabled={isSpinnerRunning()}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600 disabled:bg-slate-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSpinnerRunning()}
              className="bg-[#29D30A] w-full h-12 text-white rounded disabled:bg-[#bcfab1]"
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordModal;
