import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const UpdateReturnModal = ({ title, onClose, onConfirm, data }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-3">
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                name="customerName"
                placeholder="Customer Name"
                className="h-12 rounded-md"
                {...register("customerName", { required: true })}
                isInvalid={errors.customerName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                as="textarea"
                name="returnReason"
                className="h-12 rounded-md"
                placeholder="Return Reason"
                {...register("returnReason", { required: true })}
                isInvalid={errors.returnReason}
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                {...register("status", { required: true })}
                isInvalid={errors.status}
                className="h-12 rounded-md"
                disabled={
                  data.status === "accepted" &&
                  data.refundStatus === "refunded"
                }
              >
                <option value="">Update Return Status</option>
                <option value="processing">Processing</option>
                <option value="accepted">Accepted & Refund</option>
                <option value="rejected">Rejected</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600 disabled:bg-slate-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#29D30A] w-full h-12 text-white rounded disabled:bg-[#bcfab1]"
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateReturnModal;
