import {
  faBoxesStacked,
  faChartLine,
  faClipboardList,
  faList,
  faMagnifyingGlass,
  faPersonWalkingArrowLoopLeft,
  faUserGroup,
  faUserShield,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "../pages/dashboard/dashboard";
import Orders from "../pages/orders/orders";
import Products from "../pages/products/products";
import Returns from "../pages/returns/returns";
import Reviews from "../pages/reviews/reviews";
import Users from "../pages/users/users";
import Categories from "../pages/categories/categories";
import Customers from "../pages/customers/customers";
import ActivityLogs from "../pages/activityLogs/activityLogs";

export const adminRoutes = [
  { path: "/", name: "Dashboard", component: Dashboard, icon: faChartLine },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    icon: faList,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    icon: faUserGroup,
  },
  { path: "/orders", name: "Orders", component: Orders, icon: faBoxesStacked },
  {
    path: "/products",
    name: "Products",
    component: Products,
    icon: faWarehouse,
  },
  {
    path: "/returns",
    name: "Returns",
    component: Returns,
    icon: faPersonWalkingArrowLoopLeft,
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: Reviews,
    icon: faMagnifyingGlass,
  },
];

export const superAdminRoutes = [
  { path: "/", name: "Dashboard", component: Dashboard, icon: faChartLine },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    icon: faList,
  },
  { path: "/admins", name: "Admins", component: Users, icon: faUserShield },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    icon: faUserGroup,
  },
  { path: "/orders", name: "Orders", component: Orders, icon: faBoxesStacked },
  {
    path: "/products",
    name: "Products",
    component: Products,
    icon: faWarehouse,
  },
  {
    path: "/returns",
    name: "Returns",
    component: Returns,
    icon: faPersonWalkingArrowLoopLeft,
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: Reviews,
    icon: faMagnifyingGlass,
  },
  {
    path: "/activity-logs",
    name: "Activity Logs",
    component: ActivityLogs,
    icon: faClipboardList,
  },
];
