import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModal = ({ title, closeModal, onConfirm, description }) => {
  return (
    <Modal show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className="font-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <div className="flex items-center w-full gap-2">
          <button
            onClick={closeModal}
            type="button"
            className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-[#29D30A] w-full h-12 text-white rounded"
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
