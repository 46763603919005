import { toast } from "react-toastify";
import api from "../../system/api";
import { failureAction, successAction } from "../../shared/utility";
import { dashboardReduxConstants, systemReduxContant } from "../../constants";

const { GET_GOOGLE_ANALYTICS, GET_DASHBOARD_STATS } = dashboardReduxConstants;

const getGoogleAnalytics = (params) => async (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_GOOGLE_ANALYTICS)
  );
  api
    .get(`/api/analytics`, {
      params: params,
    })
    .then(
      (response) => {
        dispatch(
          successAction(GET_GOOGLE_ANALYTICS, {
            ...response,
          })
        );
      },
      (error) => {
        dispatch(failureAction(GET_GOOGLE_ANALYTICS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(
          systemReduxContant.SPINNER_DEACTIVATE,
          GET_GOOGLE_ANALYTICS
        )
      );
    });
};

const getDashboardStats = (params) => async (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_DASHBOARD_STATS)
  );
  api
    .get(`/user/getdashboardstats`, {
      params,
    })
    .then(
      (response) => {
        dispatch(successAction(GET_DASHBOARD_STATS, response));
      },
      (error) => {
        dispatch(failureAction(GET_DASHBOARD_STATS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(
          systemReduxContant.SPINNER_DEACTIVATE,
          GET_DASHBOARD_STATS
        )
      );
    });
};

export const dashboardActions = {
  getGoogleAnalytics,
  getDashboardStats,
};
