// src/store.js
import { createStore, combineReducers, applyMiddleware } from "redux";
import { allReducers } from "../redux/reducers";
import { thunk } from "redux-thunk";

const rootReducer = combineReducers({
  ...allReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
