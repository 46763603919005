import { toast } from "react-toastify";
import { returnsReduxConstant, systemReduxContant } from "../../constants";
import { failureAction, successAction } from "../../shared/utility";
import api from "../../system/api";

const { GET_ALL_RETURNS, UPDATE_RETURN, PROCESS_REFUND } = returnsReduxConstant;

const getAllReturns = (params) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_RETURNS));
  api
    .get("/returnProduct/getAllReturnProducts", { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_RETURNS, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_RETURNS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_RETURNS)
      );
    });
};

const updateReturn = (id, data, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_RETURN));
  api
    .put(`/returnProduct/updateReturnProduct/${id}`, data)
    .then(
      (response) => {
        dispatch(successAction(UPDATE_RETURN, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllReturns(params));
      },
      (error) => {
        dispatch(failureAction(UPDATE_RETURN, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_RETURN)
      );
    });
};

const processRefund = (data) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, PROCESS_REFUND));
  api
    .post(`/payment/refund`, data)
    .then(
      (response) => {
        dispatch(successAction(PROCESS_REFUND, response));
      },
      (error) => {
        dispatch(failureAction(PROCESS_REFUND, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, PROCESS_REFUND)
      );
    });
};

export const returnActions = {
  getAllReturns,
  updateReturn,
  processRefund,
};
