import React from "react";

const Paginator = ({
  table,
  rowCount,
  totalRows,
  currentRows,
  currentPage,
  handlePageChange,
}) => {
  const pageCount = table.getPageCount();

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 3) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(currentPage - 1, 1);
      let endPage = Math.min(startPage + 2, pageCount);

      if (endPage - startPage < 2) {
        startPage = Math.max(endPage - 2, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
    return pageNumbers;
  };

  const getCanPreviousPage = () => currentPage > 1;
  const getCanNextPage = () => currentPage < pageCount;

  return (
    <>
      <div className="h-2" />
      <div className="flex md:flex-row flex-col items-center justify-between gap-2">
        <div className="text-[#29D30A] font-medium">
          Showing {currentRows} of {totalRows}
        </div>
        {totalRows > rowCount && ( // Ensure this condition is meaningful
          <div className="flex items-center gap-2">
            <button
              className="rounded p-2 bg-[#29D30A] text-white disabled:cursor-not-allowed"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={!getCanPreviousPage()}
            >
              Previous
            </button>
            <div className="rounded bg-slate-100 flex">
              {getPageNumbers().map((number) => (
                <button
                  key={number}
                  className={`py-2 px-2.5 disabled:cursor-not-allowed rounded ${
                    number === currentPage ? "bg-[#29D30A] text-white" : ""
                  }`}
                  disabled={number === currentPage}
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              ))}
            </div>
            <button
              className="border rounded p-2 bg-[#29D30A] text-white disabled:cursor-not-allowed"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={!getCanNextPage()}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Paginator;
