import { orderReduxConstant } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { GET_ALL_ORDERS, ORDER_DETAILS } = orderReduxConstant;

const initialState = {
  allOrders: [],
  ordersPagination: {
    pageSize: 10,
    totalElements: 0,
    pageNumber: 0,
  },
  orderDetails: {},
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_ORDERS):
      return {
        ...state,
        allOrders: action.payload?.data,
        ordersPagination: action.payload?.page,
      };
    case successReducerType(ORDER_DETAILS):
      return {
        ...state,
        orderDetails: action.payload,
      };
    default:
      return state;
  }
};
