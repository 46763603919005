import { dashboardReduxConstants } from "../../constants";
import { failureReducerType, successReducerType } from "../../shared/utility";

const initalState = {
  googleAnalycticsData: {},
  dashboardStats: {
    filteredResult: {
      totalCustomers: 0,
      totalRevenue: 0,
      totalSold: 0,
    },
    previousDataResult: {
      totalCustomers: 0,
      totalRevenue: 0,
      totalSold: 0,
    },
  },
};

export const dashboard = (state = initalState, action) => {
  switch (action.type) {
    case successReducerType(dashboardReduxConstants.GET_GOOGLE_ANALYTICS):
      return {
        ...state,
        googleAnalycticsData: action.payload,
      };
    case failureReducerType(dashboardReduxConstants.GET_GOOGLE_ANALYTICS):
      return {
        ...state,
        googleAnalycticsData: initalState.googleAnalycticsData,
      };
    case successReducerType(dashboardReduxConstants.GET_DASHBOARD_STATS):
      return {
        ...state,
        dashboardStats: action.payload,
      };
    case failureReducerType(dashboardReduxConstants.GET_DASHBOARD_STATS):
      return {
        ...state,
        dashboardStats: initalState.dashboardStats,
      };

    default:
      return state;
  }
};
