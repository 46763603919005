import { toast } from "react-toastify";
import { authReduxConstants, systemReduxContant } from "../../../constants";
import {
  failureAction,
  setItemTolocalStorage,
  successAction,
} from "../../../shared/utility";
import { encryptObj } from "../../../system/encryption";
import api from "../../../system/api";

const { LOGIN, LOGOUT } = authReduxConstants;

const loginUser = (credentials, funcExecuteOnSuccess) => async (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, LOGIN));
  api
    .post("/user/login/admin", credentials)
    .then((response) => {
      // Handle successful response
      dispatch(successAction(LOGIN, response.user));
      encryptObj("payload", response.user);
      setItemTolocalStorage("userRole", response.user.role);
      funcExecuteOnSuccess();
    })
    .catch((error) => {
      // Handle error
      dispatch(failureAction(LOGIN, error.message));
      toast.error(error?.message);
    })
    .finally(() => {
      dispatch(successAction(systemReduxContant.SPINNER_DEACTIVATE, LOGIN));
    });
};

const logoutUser = (funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(LOGOUT, ""));
  localStorage.clear();
  funcExecuteOnSuccess();
};

export const authAtions = {
  loginUser,
  logoutUser,
};
