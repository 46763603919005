import { returnsReduxConstant } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { GET_ALL_RETURNS } = returnsReduxConstant;

const initialState = {
  allReturns: [],
  returnsPagination: {
    pageSize: 10,
    totalElements: 10,
    pageNumber: 0,
  },
};

export const returnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_RETURNS):
      return {
        ...state,
        allReturns: action.payload.data,
        returnsPagination:action.payload.page
      };

    default:
      return state;
  }
};
