import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Header from "../../shared/components/header/header";
import { convertToReadableDate } from "../../shared/utility";
import ReactTable from "../../shared/components/reactTable/reactTable";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import { viewCustomerDetailsModal } from "../../shared";
import { customersActions } from "../../redux/actions";

const Customers = () => {
  //Getting state and action from redux
  const dispatch = useDispatch();
  const { allCustomer, customersPagination } = useSelector(
    (state) => state.customersReducer
  );

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "updatedAt", desc: true }]);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: () => "Name",
      },
      {
        accessorKey: "email",
        header: () => "Email",
      },
      {
        accessorKey: "createdAt",
        header: () => "Created At",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "updatedAt",
        header: () => "Updated At",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "isVerify",
        header: () => "Verified",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue ? (
                <span className="text-green-600">Verified</span>
              ) : (
                <span className="text-red-600 ">Not-Verified</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "Details",
        cell: ({ row: { original } }) => {
          return (
            <>
              <FontAwesomeIcon
                icon={faEye}
                type="button"
                className="mx-2"
                onClick={() => viewCustomerDetails(original)}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNumber) => {
    dispatch(
      customersActions.getAllCustomers({
        page: pageNumber,
        limit: 10,
        searchQuery: search,
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const viewCustomerDetails = (data) => {
    setComponent(
      viewCustomerDetailsModal({
        onClose: closeModal,
        title: "Customer Details",
        data,
      })
    );
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const onRefresh = () => {
    getData(1);
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting[0]?.id, sorting[0]?.desc]);

  return (
    <Container fluid>
      {showModal && modalElement}
      <Header
        title="Customers"
        search={search}
        setOnSearch={setSearch}
        refreshButtonName="Refresh"
        refreshButtonOnClick={onRefresh}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          columns={columns}
          data={allCustomer || []}
          onSort={onSort}
          sortBy={sorting}
          onPageChange={onPageChange}
          rowsPerPage={customersPagination.pageSize}
          pageNumber={customersPagination.pageNumber}
          totalRows={customersPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Customers;
