import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { usersReduxConstants } from "../../../constants";

// Define validation schema
const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().email("Invalid email format"),
  phoneNumber: yup
    .string()
    .matches(
      /^(\+?\d{1,3}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?[\d\s-]{3,}$/,
      "Invalid phone number"
    ),
  role: yup.string().required("Role is required"),
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Use Strong Password"
    ),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  status: yup.string().required("Status is required"),
});

const AddUserModal = ({ title, onClose, onConfirm, data, isEdit }) => {
  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const isSpinnerRunning = () => {
    return (
      spinnerActions.includes(usersReduxConstants.ADD_USER) ||
      spinnerActions.includes(usersReduxConstants.UPDATE_USER)
    );
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
    disabled: isSpinnerRunning(),
  });

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-3">
            <Form.Group>
              <Form.Control
                type="text"
                name="username"
                className="h-12 rounded-md"
                placeholder="username"
                {...register("username", { required: true })}
                isInvalid={errors.username}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="email"
                name="email"
                className="h-12 rounded-md"
                placeholder="email"
                {...register("email", { required: true })}
                isInvalid={errors.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                {...register("role", { required: true })}
                isInvalid={errors.role}
                className="h-12 rounded-md"
              >
                <option value="">Select user Role</option>
                <option value="admin">Admin</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="tel"
                name="phoneNumber"
                placeholder="Phone"
                className="h-12 rounded-md"
                {...register("phoneNumber", { required: true })}
                isInvalid={errors.phoneNumber}
              />
            </Form.Group>
            {!isEdit && (
              <Row className="g-1">
                <Col>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      name="password"
                      className="h-12 rounded-md"
                      placeholder="Create password"
                      {...register("password", { required: true })}
                      isInvalid={errors.password}
                    />
                  </Form.Group>
                  {errors.password && (
                    <Form.Text className="text-sm text-red-500">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      name="repeatPassword"
                      className="h-12 rounded-md"
                      placeholder="Repeat Password"
                      {...register("repeatPassword", { required: true })}
                      isInvalid={errors.repeatPassword}
                    />
                  </Form.Group>
                  {errors.password && (
                    <Form.Text className="text-sm text-red-500">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            )}
            <Form.Group>
              <Form.Select
                {...register("status", { required: true })}
                isInvalid={errors.status}
                className="h-12 rounded-md"
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inActive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center w-full gap-2">
            <button
              type="button"
              onClick={closeModal}
              disabled={isSpinnerRunning()}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600 disabled:bg-slate-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSpinnerRunning()}
              className="bg-[#29D30A] w-full h-12 text-white rounded disabled:bg-[#bcfab1]"
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
