import { systemReduxContant } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { SPINNER_ACTIVATE, SPINNER_DEACTIVATE } = systemReduxContant;

const initialState = {
  spinnerActions: [],
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(SPINNER_ACTIVATE):
      return {
        ...state,
        spinnerActions: [...state.spinnerActions, action.payload],
      };
    case successReducerType(SPINNER_DEACTIVATE):
      return {
        ...state,
        spinnerActions: state.spinnerActions.filter(
          (k) => k !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default systemReducer;
