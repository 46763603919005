export const orderReduxConstant = {
  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  UPDATE_ORDER: "UPDATE_ORDER",
  ORDER_DETAILS: "ORDER_DETAILS",
};

export const orderPageFilters = [
  { name: "All", value: "" },
  { name: "Pending", value: "pending" },
  {
    name: "Cancelled",
    value: "cancel",
  },
  {
    name: "Completed",
    value: "completed",
  },
  {
    name: "Refunded",
    value: "refunded",
  },
];
