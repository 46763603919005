import React, { useState, useEffect } from "react";
import "@gooddata/sdk-ui-filters/styles/css/main.css";
import { DateFilter } from "@gooddata/sdk-ui-filters";

import {
  DATE_FILTER_MODE,
  DEFAULT_DATE_FORMAT,
  availableGranularities,
  localIdentifierConstants,
  defaultDateFilterOptions,
  dateFilterOptionConstants,
  dateFilterGranularityConstants,
} from "./dateFilterConstants";

const { THIS_QUARTER, LAST_QUARTER, LAST_4_QUARTERS } =
  localIdentifierConstants;
const { DATE, MONTH, QUARTER, YEAR } = dateFilterGranularityConstants;
const { ALL_TIME, ABSOLUTE_FROM, RELATIVE_PRESET } = dateFilterOptionConstants;

const defaultDateState = {
  selectedFilterOption: {
    to: 0,
    from: -6,
    name: "",
    visible: true,
    granularity: DATE,
    type: RELATIVE_PRESET,
    localIdentifier: localIdentifierConstants.LAST_7_DAYS,
  },
  excludeCurrentPeriod: false,
};

const CustomDateFilter = ({ dateState, setDateState, className = "" }) => {
  const [state, setState] = useState(defaultDateState);

  const onApply = (selectedFilterOption, excludeCurrentPeriod) => {
    const dataToSend = {
      ...conversionSelectedDate(selectedFilterOption),
      selectedFilterOption,
      excludeCurrentPeriod,
    };
    const updatedObj = { selectedFilterOption, excludeCurrentPeriod };
    handleSubmit(updatedObj, dataToSend);
  };

  const onCancel = () => {
    const dataToSend = {
      ...conversionSelectedDate(
        defaultDateFilterOptions.relativePreset["GDC.time.date"][2]
      ),
      ...defaultDateState,
    };
    const updatedObj = { ...defaultDateState };
    handleSubmit(updatedObj, dataToSend);
  };

  const handleSubmit = (updatedState, updatedData) => {
    setState(updatedState);
    setDateState(updatedData);
  };

  const conversionSelectedDate = (selectedDate) => {
    const { type, to, from, granularity, localIdentifier } = selectedDate;

    // Get the current date at midnight
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Get the current month and year
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the start date for the current quarter
    const currentQuarter = Math.floor(currentMonth / 3) * 3;
    const currentQuarterStart = new Date(currentYear, currentQuarter, 1);

    let startDate;
    let endDate;

    switch (type) {
      case ALL_TIME:
        startDate = new Date("2024-05-01T00:00:00.000Z");
        endDate = new Date(currentDate);
        endDate.setHours(23, 59, 59, 999);
        break;

      case ABSOLUTE_FROM:
        startDate = new Date(from);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(to);
        endDate.setHours(23, 59, 59, 999);
        break;

      case RELATIVE_PRESET:
        switch (granularity) {
          case YEAR:
            startDate = new Date(currentYear + from, 0, 1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(currentYear + to, 11, 31);
            endDate.setHours(23, 59, 59, 999);
            break;

          case MONTH:
            startDate = new Date(currentYear, currentMonth + from, 1);
            startDate.setHours(0, 0, 0, 0);
            const toMonth = currentMonth + to + 1;
            const lastDateOfMonth = new Date(currentYear, toMonth, 0).getDate();
            endDate = new Date(currentYear, toMonth - 1, lastDateOfMonth);
            endDate.setHours(23, 59, 59, 999);
            break;

          case DATE:
            startDate = new Date(currentDate);
            startDate.setDate(currentDate.getDate() + from);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(currentDate);
            endDate.setDate(currentDate.getDate() + to);
            endDate.setHours(23, 59, 59, 999);
            break;

          case QUARTER:
            switch (localIdentifier) {
              case THIS_QUARTER:
                startDate = new Date(currentQuarterStart);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentYear, currentQuarter + 3, 0);
                endDate.setHours(23, 59, 59, 999);
                break;

              case LAST_QUARTER:
                startDate = new Date(currentYear, currentQuarter - 3, 1);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(currentYear, currentQuarter, 0);
                endDate.setHours(23, 59, 59, 999);
                break;

              case LAST_4_QUARTERS:
                const quarters = Array.from({ length: 4 }, (_, i) => {
                  const start = new Date(currentQuarterStart);
                  start.setMonth(start.getMonth() - i * 3);
                  start.setHours(0, 0, 0, 0);
                  const end = new Date(
                    start.getFullYear(),
                    start.getMonth() + 3,
                    0
                  );
                  end.setHours(23, 59, 59, 999);
                  return { start, end };
                }).reverse(); // Reverse to have the most recent quarter first
                startDate = quarters[0].start;
                endDate = quarters[3].end;
                break;

              default:
                // Handle other cases for localIdentifier if needed
                break;
            }
            break;

          default:
            // Handle other cases for granularity if needed
            break;
        }
        break;

      default:
        // Handle other cases for type if needed
        break;
    }

    return { startDate, endDate };
  };

  const onOpen = () => {
    const [container] = document.getElementsByClassName("main-container");
    if (container) {
      container.style.setProperty("overflow-y", "hidden", "important");
    }
  };

  const onClose = () => {
    const [container] = document.getElementsByClassName("main-container");
    if (container) {
      container.removeAttribute("style");
    }
  };

  useEffect(() => {
    if (dateState?.selectedFilterOption) {
      const { excludeCurrentPeriod, selectedFilterOption } = dateState;
      setState({ selectedFilterOption, excludeCurrentPeriod });
    }
  }, [dateState]);

  return (
    <div
      className={`good-data-date-filter ${className}`}
      aria-label="date-filter"
    >
      <DateFilter
        onOpen={onOpen}
        onClose={onClose}
        onApply={onApply}
        onCancel={onCancel}
        customFilterName="Selected date"
        dateFormat={DEFAULT_DATE_FORMAT}
        dateFilterMode={DATE_FILTER_MODE}
        filterOptions={defaultDateFilterOptions}
        availableGranularities={availableGranularities}
        excludeCurrentPeriod={state.excludeCurrentPeriod}
        selectedFilterOption={state.selectedFilterOption}
      />
    </div>
  );
};

export default CustomDateFilter;
