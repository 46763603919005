export const categoryReduxConstant = {
  CREATE_CATEGORY: "CREATE_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_CATEGORY_DETAIL: "GET_CATEGORY_DETAIL",
  GET_SUB_CATEGORIES: "GET_SUB_CATEGORIES",
  RESET_SUB_CATEGORIES: "RESET_SUB_CATEGORIES",
  SEARCH_CATEGORY: "SEARCH_CATEGORY",
};
