import { usersReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const initialState = {
  allUsers: [],
  usersPagination: {
    pageSize: 10,
    totalElements: 0,
    pageNumber: 0,
  },
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(usersReduxConstants.GET_ALL_USERS_AS_ADMIN):
      return {
        ...state,
        allUsers: action.payload.data,
        usersPagination: action.payload.page,
      };

    default:
      return state;
  }
};
