import { getDecryptedPayload } from "../../system/encryption";
import { authReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { LOGIN, LOGOUT } = authReduxConstants;

const payload = getDecryptedPayload();
const token = payload.token;

const initialState = {
  user: { ...payload, token: null } || null,
  token: token || "",
  isAuthenticated: token?.length > 0,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(LOGIN):
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case successReducerType(LOGOUT):
      return {
        ...state,
        token: "",
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
