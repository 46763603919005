import AddCategoriesModal from "./addCategoriesModal";
import AddProductModal from "./addProductModal";
import AddUserModal from "./addUserModal";
import ChangeUserPassword from "./changeUserPassword";
import ConfirmationModal from "./confirmationModal";
import ForgotPasswordModal from "./forgotPasswordModal";
import UpdateOrderModal from "./updateOrderModal";
import UpdateReturnModal from "./updateReturnModal";
import CustomerDetailsModal from "./viewCustomerDetailsModal";
import ViewReturnsProductsModal from "./viewReturnsProductsModal";

export const getAddUserModal = (props) => <AddUserModal {...props} />;

export const openConfirmationModal = (props) => (
  <ConfirmationModal {...props} />
);

export const openChangePasswordModal = (props) => (
  <ChangeUserPassword {...props} />
);

export const viewCustomerDetailsModal = (props) => (
  <CustomerDetailsModal {...props} />
);

export const getUpdateOrderModal = (props) => <UpdateOrderModal {...props} />;

export const getAddProductModal = (props) => <AddProductModal {...props} />;

export const getUpdateReturnModal = (props) => <UpdateReturnModal {...props} />;

export const getViewReturnProductModal = (props) => (
  <ViewReturnsProductsModal {...props} />
);
export const getCreateCategoryModal = (props) => (
  <AddCategoriesModal {...props} />
);
export const getForgotPasswordModal = (props) => (
  <ForgotPasswordModal {...props} />
);
