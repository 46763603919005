import { toast } from "react-toastify";

import { productsReduxConstants, systemReduxContant } from "../../../constants";
import { failureAction, successAction } from "../../../shared/utility";
import api from "../../../system/api";

const {
  ADD_PRODUCT,
  GET_ALL_PRODUCTS,
  DELET_PRODUCT,
  UPDATE_PRODUCT,
  UPLOAD_PRODUCTS_IN_BULK,
  GET_ALL_TOP_RATED_PRODUCTS,
} = productsReduxConstants;

const getAllProducts = (params) => (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_PRODUCTS)
  );
  api
    .get("/product/getAllProductsAdmin", { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_PRODUCTS, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_PRODUCTS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_PRODUCTS)
      );
    });
};

const addProduct = (data, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, ADD_PRODUCT));
  api
    .post(`/product/createProduct`, data)
    .then(
      (response) => {
        dispatch(successAction(ADD_PRODUCT, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllProducts(params));
      },
      (error) => {
        dispatch(failureAction(ADD_PRODUCT, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, ADD_PRODUCT)
      );
    });
};

const deleteProduct = (id, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, DELET_PRODUCT));
  api
    .delete(`/product/deleteProduct/${id}`)
    .then(
      (response) => {
        dispatch(successAction(DELET_PRODUCT, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllProducts(params));
      },
      (error) => {
        dispatch(failureAction(DELET_PRODUCT, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, DELET_PRODUCT)
      );
    });
};

const updateProduct = (data, params, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_PRODUCT));
  api
    .put(`/product/updateProduct/${data._id}`, data)
    .then(
      (response) => {
        dispatch(successAction(UPDATE_PRODUCT, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
        dispatch(getAllProducts(params));
      },
      (error) => {
        dispatch(failureAction(UPDATE_PRODUCT, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_PRODUCT)
      );
    });
};

const getAllTopRatedProducts = (funcExecuteOnSuccess) => (dispatch) => {
  dispatch(
    successAction(
      systemReduxContant.SPINNER_ACTIVATE,
      GET_ALL_TOP_RATED_PRODUCTS
    )
  );
  api
    .get(`/product/getTopRatedProductsOverall`)
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_TOP_RATED_PRODUCTS, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
      },
      (error) => {
        dispatch(failureAction(GET_ALL_TOP_RATED_PRODUCTS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(
          systemReduxContant.SPINNER_DEACTIVATE,
          GET_ALL_TOP_RATED_PRODUCTS
        )
      );
    });
};

const uploadProductsInBulk =
  (formdata, params, funcExecuteOnSuccess) => (dispatch) => {
    dispatch(
      successAction(
        systemReduxContant.SPINNER_ACTIVATE,
        UPLOAD_PRODUCTS_IN_BULK
      )
    );
    api
      .post(`/product/uploadFromExcel`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        (response) => {
          dispatch(successAction(UPLOAD_PRODUCTS_IN_BULK, response));
          funcExecuteOnSuccess && funcExecuteOnSuccess();
          dispatch(getAllProducts(params));
        },
        (error) => {
          dispatch(failureAction(UPLOAD_PRODUCTS_IN_BULK, error));
          toast.error(error.message);
        }
      )
      .finally(() => {
        dispatch(
          successAction(
            systemReduxContant.SPINNER_DEACTIVATE,
            UPLOAD_PRODUCTS_IN_BULK
          )
        );
      });
  };

export const productsActions = {
  addProduct,
  deleteProduct,
  updateProduct,
  getAllProducts,
  uploadProductsInBulk,
  getAllTopRatedProducts,
};
