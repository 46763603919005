import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

import CustomeDateFilter from "../dateFilter/dateFilter.jsx";

const Header = ({
  title,
  search,
  filter,
  filters,
  dateState,
  setFilter,
  buttonName,
  setOnSearch,
  secondFilter,
  setDateState,
  secondFilters,
  refreshButtonName,
  refreshButtonOnClick,
  buttonOnClick,
  setSecondFilter,
}) => {
  const user = useSelector((state) => state.authReducer.user);

  return (
    <>
      <div className="pt-3 flex items-center justify-between border-b pb-2 w-full">
        <h1 className="text-3xl font-medium">{title}</h1>
        <div className="flex items-center justify-between gap-2">
          <Avatar name={user?.username} round size="50" />
        </div>
      </div>
      <div className="gap-2 py-3 flex items-center justify-between">
        <div className="flex md:flex-row flex-col md:items-center justify-start gap-2 md:w-2/5 w-full">
          {dateState && (
            <div>
              <CustomeDateFilter
                dateState={dateState}
                setDateState={setDateState}
              />
            </div>
          )}
          <div className="flex justify-between gap-2">
            <InputGroup className="w-4/5 border rounded-md">
              <InputGroup.Text className=" px-2 bg-transparent border-0 box-border-0">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <DebounceInput
                type="text"
                value={search}
                onChange={(e) => setOnSearch(e.target.value)}
                debounceTimeout={1000}
                placeholder="Type to search"
                className="form-control  h-12 border-0 outline-none shadow-none p-0"
              />
            </InputGroup>
            {filters && (
              <Form.Select
                aria-label="filter"
                onChange={setFilter}
                className="h-12 w-2/5"
                value={filter}
              >
                {filters.map((i) => (
                  <option key={i.value} value={i.value}>
                    {i.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </div>
          {secondFilters && (
            <Form.Select
              aria-label="filter"
              onChange={setSecondFilter}
              className="h-12 w-2/5"
              value={secondFilter}
            >
              {secondFilters.map((i) => (
                <option key={i.value} value={i.value}>
                  {i.name}
                </option>
              ))}
            </Form.Select>
          )}
        </div>
        {buttonName && (
          <button
            className="system-bg-color border-0 shadow-none min-w-fit px-4 h-12 font-semibold rounded text-white"
            onClick={buttonOnClick}
          >
            {buttonName}
          </button>
        )}
        {refreshButtonName && (
          <button
            className="bg-black border-0 shadow-none min-w-fit px-4 h-12 font-semibold rounded text-white self-end"
            onClick={() => {
              refreshButtonOnClick();
            }}
          >
            {refreshButtonName}
          </button>
        )}
      </div>
    </>
  );
};

export default Header;
