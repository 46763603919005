import { toast } from "react-toastify";
import { ratingReduxConstants, systemReduxContant } from "../../constants";
import { failureAction, successAction } from "../../shared/utility";
import api from "../../system/api";

const { GET_ALL_RATING, UPDATE_RATING } = ratingReduxConstants;

const getAllRating = (params) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_RATING));
  api
    .get("/rating/getAllRating", { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_RATING, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_RATING, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, GET_ALL_RATING)
      );
    });
};

const updateRating = (id, body, funcExecuteOnSuccess) => (dispatch) => {
  dispatch(successAction(systemReduxContant.SPINNER_ACTIVATE, UPDATE_RATING));
  api
    .put(`/rating/update/${id}`, body)
    .then(
      (response) => {
        dispatch(successAction(UPDATE_RATING, response));
        funcExecuteOnSuccess && funcExecuteOnSuccess();
      },
      (error) => {
        dispatch(failureAction(UPDATE_RATING, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(systemReduxContant.SPINNER_DEACTIVATE, UPDATE_RATING)
      );
    });
};

export const ratingActions = {
  getAllRating,
  updateRating,
};
