import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Image, Spinner } from "react-bootstrap";

import { authReduxConstants } from "../../constants";
import logo from "../../assets/images/CIWUW-lg.png";
import { authAtions } from "../../redux/actions/auth/auth.action";
import { getForgotPasswordModal } from "../../shared";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import { userActions } from "../../redux/actions";

// Define validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const Login = () => {
  const dispatch = useDispatch();
  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const isSpinnerRunning = () =>
    spinnerActions.includes(authReduxConstants.LOGIN);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    disabled: isSpinnerRunning(),
  });
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(
      authAtions.loginUser(data, () => {
        reset();
        navigate("/", { replace: false });
      })
    );
  };

  const forgotPassword = (e) => {
    setComponent(
      getForgotPasswordModal({
        onClose: closeModal,
        onConfirm: (data, reset) => {
          dispatch(
            userActions.forgotPassword(data.email, () => {
              reset();
              closeModal();
            })
          );
        },
      })
    );
  };

  return (
    <Container
      fluid
      className="w-full h-screen flex justify-center items-center"
    >
      {showModal && modalElement}
      <Container className="bg-slate-100 px-4 md:py-14 py-5 my-auto space-y-5 md:w-2/5 w-full rounded-xl shadow-md ">
        <div className="flex justify-center">
          <Image src={logo} width={200} height={"auto"} />
        </div>

        <h1 className="text-2xl font-semibold text-center">LOGIN</h1>
        <Form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Control
              type="email"
              className="h-14 rounded-xl"
              placeholder="example@email.com"
              {...register("email")}
              isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder="password"
              className="h-14 rounded-xl"
              {...register("password")}
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password && errors.password.message}
            </Form.Control.Feedback>
          </Form.Group>

          <button
            type="submit"
            disabled={isSpinnerRunning()}
            className="h-14 w-full rounded-xl system-bg-color text-white font-semibold mt-5"
          >
            Sign In {isSpinnerRunning() && <Spinner size="sm" />}
          </button>
        </Form>
        <div className="text-center">
          <button className="text-blue-500" onClick={forgotPassword}>
            Forgot Password
          </button>
        </div>
      </Container>
    </Container>
  );
};

export default Login;
