import { productsReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { GET_ALL_PRODUCTS, GET_ALL_TOP_RATED_PRODUCTS } = productsReduxConstants;

const initialState = {
  allProducts: [],
  allTopRatedProducts: [],
  productsPagination: {
    pageSize: 10,
    totalElements: 0,
    pageNumber: 0,
  },
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_PRODUCTS):
      return {
        ...state,
        allProducts: action.payload.data,
        productsPagination: action.payload.page,
      };
    case successReducerType(GET_ALL_TOP_RATED_PRODUCTS):
      return {
        ...state,
        allTopRatedProducts: action.payload,
      };

    default:
      return state;
  }
};
