import React from "react";
import { Image, Modal } from "react-bootstrap";

const ViewReturnsProductsModal = ({ title, onClose, data }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {data.map((product) => (
            <div className="bg-slate-100 p-2 mb-2 rounded-md">
              <Image
                src={product.image || ""}
                width={200}
                height={200}
                alt="P-Image"
                className="rounded-md mx-auto"
              />
              <p>
                <strong>Product Name:</strong> {product.name}
              </p>
              <div className="flex items-center">
                <strong className="font-semibold">Color:</strong>
                <div
                  className="h-4 w-4 rounded-full ms-2"
                  style={{ backgroundColor: product.color }}
                ></div>
              </div>
              <p>
                <strong>Size:</strong> XL
              </p>
              <p>
                <strong>Price:</strong> £{product.price / 100}
              </p>
              <p>
                <strong>Quantity:</strong> {product.quantity}
              </p>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewReturnsProductsModal;
