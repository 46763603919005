import { useState } from "react";

const useModalDisplay = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalElement, setModalElement] = useState({});

  //To open the modal
  const openModal = () => {
    setShowModal(true);
  };

  //To close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  //Setting modal component
  const setComponent = (component) => {
    setModalElement(component);
    openModal();
  };

  return [showModal, closeModal, modalElement, setComponent];
};

export default useModalDisplay;
