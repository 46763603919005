import { customersReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const initialState = {
  allCustomer: [],
  customersPagination: {
    pageSize: 10,
    totalElements: 0,
    pageNumber: 0,
  },
};

export const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case successReducerType(customersReduxConstants.GET_ALL_CUSTOMERS):
      return {
        ...state,
        allCustomer: action.payload.data,
        customersPagination: action.payload.page,
      };

    default:
      return state;
  }
};
