export const productsReduxConstants = {
  ADD_PRODUCT: "ADD_PRODUCT",
  DELET_PRODUCT: "DELET_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_ALL_TOP_RATED_PRODUCTS: "GET_ALL_TOP_RATED_PRODUCTS",
  UPLOAD_PRODUCTS_IN_BULK: "UPLOAD_PRODUCTS_IN_BULK",
};
export const sizesArray = [
  { name: "6", value: "6" },
  { name: "8", value: "8" },
  { name: "12", value: "12" },
  { name: "14", value: "14" },
  { name: "16", value: "16" },
  { name: "18", value: "18" },
  { name: "20", value: "20" },
  { name: "22", value: "22" },
  { name: "24", value: "24" },
];

export const bodyParts = [
  {
    id: "e3ca2552-fdea-43e5-b2f6-08dd273233e7",
    location: "head",
    label: "Head",
    dateCreated: "2024-12-28T16:29:58.4976734+05:00",
  },
  {
    id: "9344cd8e-2a6c-46c4-b2f7-08dd273233e7",
    location: "neck",
    label: "Neck",
    dateCreated: "2024-12-28T16:29:58.4976742+05:00",
  },
  {
    id: "55d7cdf8-c01c-4b19-b2f8-08dd273233e7",
    location: "chestleft",
    label: "Chest Left",
    dateCreated: "2024-12-28T16:29:58.4976745+05:00",
  },
  {
    id: "2e68d6bd-bce1-4543-b2f9-08dd273233e7",
    location: "chestright",
    label: "Chest Right",
    dateCreated: "2024-12-28T16:29:58.4976748+05:00",
  },
  {
    id: "358e26aa-7c16-44c0-b2fa-08dd273233e7",
    location: "shoulderleft",
    label: "Shoulder Left",
    dateCreated: "2024-12-28T16:29:58.4976751+05:00",
  },
  {
    id: "3d5c6dc2-1e5b-47d0-b2fb-08dd273233e7",
    location: "lowerback",
    label: "Lower Back",
    dateCreated: "2024-12-28T16:29:58.4976757+05:00",
  },
  {
    id: "79f0951f-8ad4-4f39-b2fc-08dd273233e7",
    location: "leftupperarm",
    label: "Left Upper Arm",
    dateCreated: "2024-12-28T16:29:58.497676+05:00",
  },
  {
    id: "f70828c7-0db2-4255-b2fd-08dd273233e7",
    location: "backupperarmleft",
    label: "Back Upper Arm Left",
    dateCreated: "2024-12-28T16:29:58.4976762+05:00",
  },
  {
    id: "428b87a1-7974-48ff-b2fe-08dd273233e7",
    location: "backforearmleft",
    label: "Back Fore Arm Left",
    dateCreated: "2024-12-28T16:29:58.4976766+05:00",
  },
  {
    id: "647a26bf-5dad-470f-b2ff-08dd273233e7",
    location: "leftforearm",
    label: "Left Fore Arm",
    dateCreated: "2024-12-28T16:29:58.497677+05:00",
  },
  {
    id: "1e7bb5d6-be3c-479b-b300-08dd273233e7",
    location: "lefthand",
    label: "Left Hand",
    dateCreated: "2024-12-28T16:29:58.4976773+05:00",
  },
  {
    id: "3c5e77cf-c511-41f7-b301-08dd273233e7",
    location: "stomach",
    label: "Stomach",
    dateCreated: "2024-12-28T16:29:58.4976776+05:00",
  },
  {
    id: "ba2b7239-cbbe-42e2-b302-08dd273233e7",
    location: "shoulderright",
    label: "Right Shoulder",
    dateCreated: "2024-12-28T16:29:58.4976779+05:00",
  },
  {
    id: "9d5ba68f-4e7a-4bc4-b303-08dd273233e7",
    location: "rightupperarm",
    label: "Right Upper Arm",
    dateCreated: "2024-12-28T16:29:58.4976781+05:00",
  },
  {
    id: "f1a91b7c-5563-433d-b304-08dd273233e7",
    location: "rightforearm",
    label: "Right Fore Arm",
    dateCreated: "2024-12-28T16:29:58.4976784+05:00",
  },
  {
    id: "c53c1aee-c958-4564-b305-08dd273233e7",
    location: "backforearmright",
    label: "Back Fore Arm Right",
    dateCreated: "2024-12-28T16:29:58.4976787+05:00",
  },
  {
    id: "d1a83736-6ceb-484d-b306-08dd273233e7",
    location: "righthand",
    label: "Right Hand",
    dateCreated: "2024-12-28T16:29:58.497679+05:00",
  },
  {
    id: "9c472a8e-e76a-4e64-b307-08dd273233e7",
    location: "waist",
    label: "Waist",
    dateCreated: "2024-12-28T16:29:58.4976856+05:00",
  },
  {
    id: "f1511c3b-ab2a-481e-b308-08dd273233e7",
    location: "upperrightthigh",
    label: "Upper Right Thigh ",
    dateCreated: "2024-12-28T16:29:58.4976858+05:00",
  },
  {
    id: "8d70b14f-6dac-4be9-b309-08dd273233e7",
    location: "upperleftthigh",
    label: "Upper Left Thigh",
    dateCreated: "2024-12-28T16:29:58.4976861+05:00",
  },
  {
    id: "f481d810-195c-4293-b30a-08dd273233e7",
    location: "lowerleftthigh",
    label: "Lower Left Thigh",
    dateCreated: "2024-12-28T16:29:58.4976864+05:00",
  },
  {
    id: "cc2201bc-5fbf-4f58-b30b-08dd273233e7",
    location: "leftshin",
    label: "Left Shin",
    dateCreated: "2024-12-28T16:29:58.4976866+05:00",
  },
  {
    id: "09cbb8a3-2e6c-41ad-b30c-08dd273233e7",
    location: "leftfoot",
    label: "Left Foot",
    dateCreated: "2024-12-28T16:29:58.4976869+05:00",
  },
  {
    id: "1489ab8c-6eb9-44c0-b30d-08dd273233e7",
    location: "leftuppershin",
    label: "Left Upper Shin",
    dateCreated: "2024-12-28T16:29:58.4976872+05:00",
  },
  {
    id: "89aa5d78-70fe-49af-b30e-08dd273233e7",
    location: "backlowerthighright",
    label: "Back Lower Thigh Right",
    dateCreated: "2024-12-28T16:29:58.4976875+05:00",
  },
  {
    id: "5470badc-75cf-41cd-b30f-08dd273233e7",
    location: "lowerrightthigh",
    label: "Lower Right Thigh",
    dateCreated: "2024-12-28T16:29:58.4976877+05:00",
  },
  {
    id: "4bf1a053-8ac2-47c4-b310-08dd273233e7",
    location: "rightuppershin",
    label: "Right Upper Shin",
    dateCreated: "2024-12-28T16:29:58.497688+05:00",
  },
  {
    id: "fbfe02df-ebe6-4574-b311-08dd273233e7",
    location: "rightshin",
    label: "Right Shin",
    dateCreated: "2024-12-28T16:29:58.4976883+05:00",
  },
  {
    id: "2259f325-57d8-4f2d-b312-08dd273233e7",
    location: "rightfoot",
    label: "Right Foot",
    dateCreated: "2024-12-28T16:29:58.4976886+05:00",
  },
  {
    id: "65f73771-4083-4ebd-b313-08dd273233e7",
    location: "backupperarmright",
    label: "Back Upper Arm Right",
    dateCreated: "2024-12-28T16:29:58.4976889+05:00",
  },
  {
    id: "b8e54b7b-c97c-434e-b314-08dd273233e7",
    location: "backupperthighright",
    label: "Back Upper Thigh Right",
    dateCreated: "2024-12-28T16:29:58.4976892+05:00",
  },
  {
    id: "f272ad1a-a6e7-4bc6-b315-08dd273233e7",
    location: "backupperthighleft",
    label: "Back Upper Thigh Left",
    dateCreated: "2024-12-28T16:29:58.4976894+05:00",
  },
  {
    id: "9d15beb9-192c-4aa7-b316-08dd273233e7",
    location: "backlowerthighleft",
    label: "Back Lower Thigh Left",
    dateCreated: "2024-12-28T16:29:58.4976897+05:00",
  },
  {
    id: "e79a6f21-eef6-4a0b-b317-08dd273233e7",
    location: "backuppercalfright",
    label: "Back Upper Calf Right",
    dateCreated: "2024-12-28T16:29:58.4976901+05:00",
  },
  {
    id: "ac6f4b0b-b861-4134-b318-08dd273233e7",
    location: "backlowercalfright",
    label: "Back Lower Calf Right",
    dateCreated: "2024-12-28T16:29:58.4976904+05:00",
  },
  {
    id: "656042f8-c902-4959-b319-08dd273233e7",
    location: "backuppercalfleft",
    label: "Back Upper Calf Left",
    dateCreated: "2024-12-28T16:29:58.4976906+05:00",
  },
  {
    id: "22f297fa-d672-4afd-b31a-08dd273233e7",
    location: "backlowercalfleft",
    label: "Back Lower Calf Left",
    dateCreated: "2024-12-28T16:29:58.4976909+05:00",
  },
];
