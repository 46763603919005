import React, { useState } from "react";
import { Col, Image, Nav, Navbar, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { adminRoutes, superAdminRoutes } from "../../../system/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAtions } from "../../../redux/actions";

import logo from "../../../assets/images/CIWUW-lg.png";
import { getItemFromLocalStorage } from "../../utility";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole = getItemFromLocalStorage("userRole");
  const routes = userRole === "admin" ? adminRoutes : superAdminRoutes;

  const [expanded, setExpanded] = useState(false);

  const logoutUser = () => {
    dispatch(
      authAtions.logoutUser(() => {
        navigate("/login", { replace: true });
      })
    );
    setExpanded(!expanded);
  };

  return (
    <Navbar
      expand="md"
      expanded={expanded}
      className="md:flex md:flex-col w-full md:w-1/5 md:h-dvh md:rounded-e-md px-3 bg-slate-100"
    >
      <div className="container px-0  flex md:flex-col h-full">
        <Navbar.Brand
          type="button"
          onClick={() => navigate("/")}
          className="text-3xl font-extrabold text-green-500"
        >
          <Image src={logo} width={100} height={"auto"} alt="System Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="w-full flex flex-col justify-between h-full md:py-3"
        >
          <Nav className="d-flex flex-md-column w-full px-0 mx-0 space-y-3">
            {routes.map((link) => (
              <LinkContainer to={link.path} key={link.name} className="w-full">
                <Nav.Link
                  className={`${
                    location.pathname === link.path
                      ? "active-navlink w-full"
                      : ""
                  } px-4 font-semibold`}
                  onClick={() => setExpanded(!expanded)}
                >
                  <Row>
                    <Col xs={1} md={3}>
                      <FontAwesomeIcon icon={link.icon} />
                    </Col>
                    <Col xs={10} md={9}>
                      {link.name}
                    </Col>
                  </Row>
                </Nav.Link>
              </LinkContainer>
            ))}
          </Nav>
          <button
            className="system-bg-color  w-5/6 p-2 rounded text-white font-semibold"
            onClick={logoutUser}
          >
            Logout
          </button>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Sidebar;
