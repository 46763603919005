import {
  systemReduxContant,
  activityLogsReduxConstants,
} from "../../constants";
import api from "../../system/api";
import { failureAction, successAction } from "../../shared/utility";
import { toast } from "react-toastify";

const { GET_ALL_ACTIVITY_LOGS } = activityLogsReduxConstants;

const getAllActivityLogs = (params) => (dispatch) => {
  dispatch(
    successAction(systemReduxContant.SPINNER_ACTIVATE, GET_ALL_ACTIVITY_LOGS)
  );
  api
    .get(`/log/getAll`, { params })
    .then(
      (response) => {
        dispatch(successAction(GET_ALL_ACTIVITY_LOGS, response));
      },
      (error) => {
        dispatch(failureAction(GET_ALL_ACTIVITY_LOGS, error));
        toast.error(error.message);
      }
    )
    .finally(() => {
      dispatch(
        successAction(
          systemReduxContant.SPINNER_DEACTIVATE,
          GET_ALL_ACTIVITY_LOGS
        )
      );
    });
};

export const activityLogsActions = {
  getAllActivityLogs,
};
