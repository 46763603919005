import React from "react";
import Sidebar from "../sidebar/sidebar";

const DashboardRender = ({ children }) => {
  return (
    <div className="dashboard md:flex">
      <Sidebar />
      <div className="w-full h-screen overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default DashboardRender;
