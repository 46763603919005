import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useModalDisplay from "../../shared/hooks/useModalDisplay";
import Header from "../../shared/components/header/header";
import { returnActions } from "../../redux/actions/index.js";
import { convertToReadableDate } from "../../shared/utility.js";
import ReactTable from "../../shared/components/reactTable/reactTable";
import {
  getUpdateReturnModal,
  getViewReturnProductModal,
} from "../../shared/index.js";

const Returns = () => {
  const dispatch = useDispatch();
  const { allReturns, returnsPagination } = useSelector(
    (state) => state.returnsReducer
  );
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "returnDate", desc: true }]);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const params = {
    page: returnsPagination.pageNumber,
    limit: returnsPagination.pageSize,
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "orderId",
        header: () => "Order Id",
      },
      {
        accessorKey: "userId.username",
        header: () => "Customer",
      },
      {
        accessorKey: "retrunProducts",
        header: "Products",
        cell: ({
          row: {
            original: { orderProducts },
          },
        }) => (
          <span
            title={orderProducts[0]?.name}
            className="cursor-pointer text-blue-500 truncate-text"
            onClick={() => openViewReturnProductModal(orderProducts)}
          >
            {orderProducts[0]?.name}
          </span>
        ),
      },
      {
        accessorKey: "returnReason",
        header: "Reason",
        cell: ({
          row: {
            original: { returnReason },
          },
        }) => (
          <div className="truncate-text m-0 p-0" title={returnReason}>
            <p className="p-0 m-0">{returnReason}</p>
          </div>
        ),
      },
      {
        accessorKey: "returnDate",
        header: "Return Date",
        cell: ({
          row: {
            original: { returnDate },
          },
        }) => convertToReadableDate(returnDate),
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue === "accepted" ? (
                <span className="text-green-600">Accepted</span>
              ) : columnValue === "processing" ? (
                <span className="text-yellow-600">Processing</span>
              ) : (
                <span className="text-red-600 ">Rejected</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "refundStatus",
        header: () => "Refund Status",
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.refundStatus === "refunded" ? "Refunded" : "Pending"}{" "}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "Actions",
        cell: ({ row: { original } }) => {
          return (
            <div className="flex items-center gap-3">
              <FontAwesomeIcon
                type="button"
                icon={faEdit}
                title="Update Return"
                onClick={() => openUpdateReturnModal(original)}
              />
              {original.refundStatus !== "refunded" &&
                original.status === "accepted" && (
                  <FontAwesomeIcon
                    type="button"
                    icon={faPaperPlane}
                    title="Manual Refund"
                    onClick={() => openUpdateReturnModal(original)}
                  />
                )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNumber) => {
    dispatch(
      returnActions.getAllReturns({
        page: pageNumber,
        limit: 10,
        searchQuery: search,
        status: filter,
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const openUpdateReturnModal = (data) => {
    setComponent(
      getUpdateReturnModal({
        data: {
          customerName: data.userId.username,
          returnReason: data.returnReason,
          status: data.status,
          refundStatus: data.refundStatus,
        },
        title: "Update Return",
        onClose: closeModal,
        dispatch,
        onConfirm: (formData, reset) => {
          const dataToSend = {
            status: formData.status,
          };
          dispatch(
            returnActions.updateReturn(data._id, dataToSend, params, () => {
              reset();
              closeModal();
            })
          );
          if (formData.status === "accepted") {
            const refundAmount =
              data.orderProducts.reduce(
                (total, product) => (total + product.price) * product.quantity,
                0
              ) / 100;
            dispatch(
              returnActions.processRefund({
                orderId: data.primaryOrderId,
                refundAmount,
              })
            );
          }
        },
      })
    );
  };

  const openViewReturnProductModal = (data) => {
    setComponent(
      getViewReturnProductModal({
        data,
        title: "Returns Products",
        onClose: closeModal,
      })
    );
  };

  const onRefresh = () => {
    getData(1);
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sorting[0]?.id, sorting[0]?.desc]);

  return (
    <Container fluid className="pb-3">
      {showModal && modalElement}
      <Header
        title="Returns"
        search={search}
        filter={filter}
        setFilter={onFilterChange}
        filters={[
          { name: "All", value: "" },
          { name: "Accepted", value: "accepted" },
          { name: "Rejected", value: "rejected" },
          { name: "Processing", value: "processing" },
        ]}
        setOnSearch={setSearch}
        refreshButtonName="Refresh"
        refreshButtonOnClick={onRefresh}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          onSort={onSort}
          sortBy={sorting}
          columns={columns}
          data={allReturns || []}
          onPageChange={onPageChange}
          rowsPerPage={returnsPagination.pageSize}
          pageNumber={returnsPagination.pageNumber}
          totalRows={returnsPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Returns;
