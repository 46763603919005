import { ratingReduxConstants } from "../../constants";
import { successReducerType } from "../../shared/utility";

const { GET_ALL_RATING } = ratingReduxConstants;

const intitialState = {
  allRatings: [],
  ratingPagination: { pageSize: 10, totalElements: 0, pageNumber: 0 },
};

export const ratingReducer = (state = intitialState, action) => {
  switch (action.type) {
    case successReducerType(GET_ALL_RATING):
      return {
        ...state,
        allRatings: action.payload.data,
        ratingPagination: action.payload.pagination,
      };

    default:
      return state;
  }
};
