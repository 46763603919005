import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";

import { productsActions } from "../../redux/actions";
import Header from "../../shared/components/header/header";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import ReactTable from "../../shared/components/reactTable/reactTable";
import { getAddProductModal, openConfirmationModal } from "../../shared";

const Products = () => {
  const dispatch = useDispatch();
  const { allProducts, productsPagination } = useSelector(
    (state) => state.productsReducer
  );

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "stock", desc: false }]);
  const fileInputRef = useRef();

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const columns = useMemo(
    () => [
      {
        accessorKey: "productName",
        header: () => "Name",
      },
      {
        accessorKey: "sizes",
        header: "Sizes",
        cell: ({ row }) => row.original.sizes.map((size) => size).join(","),
      },
      {
        accessorKey: "colors",
        header: "Colors",
        cell: ({ row }) => (
          <div className="flex items-center flex-wrap">
            {row.original.colors.map((color) => (
              <span
                className="p-2 rounded-full m-0.5"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        ),
      },
      {
        accessorKey: "shortDescription",
        header: "Description",
      },
      {
        accessorKey: "stock",
        header: () => "Stock",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue > 50 ? (
                <span className="text-green-600">{columnValue}</span>
              ) : columnValue > 15 && columnValue < 50 ? (
                <span className="text-yellow-600">{columnValue}</span>
              ) : (
                <span className="text-red-600 ">{columnValue}</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "price",
        header: () => "Price",
        cell: ({ row }) => `£${row.original.price}`,
      },
      {
        accessorKey: "discountPrice",
        header: () => "Discount Price",
        cell: ({ row }) =>
          row.original.discountPrice
            ? `£${row.original.discountPrice}`
            : "No Discount",
      },
      {
        accessorKey: "brand",
        header: () => "Brand",
      },
      {
        accessorKey: "action",
        header: () => "Actions",
        cell: ({ row: { original } }) => {
          return (
            <>
              <FontAwesomeIcon
                type="button"
                icon={faEdit}
                onClick={() => openEditProductModal(original)}
              />
              <FontAwesomeIcon
                color="red"
                type="button"
                icon={faTrash}
                className="mx-2"
                onClick={() => removeProduct(original)}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNumber) => {
    dispatch(
      productsActions.getAllProducts({
        page: pageNumber,
        limit: 10,
        searchQuery: search,
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const openAddNewProductModal = () => {
    setComponent(
      getAddProductModal({
        title: "Add Product",
        onClose: closeModal,
        onConfirm: (data, reset) => {
          const dataToSend = {
            ...data,
            sizes: data.sizes.map((size) => size.value),
            colors: data.colors.map((color) => color.value),
          };
          dispatch(
            productsActions.addProduct(
              dataToSend,
              {
                page: productsPagination.pageNumber,
                limit: productsPagination.pageSize,
              },
              () => {
                reset();
                closeModal();
              }
            )
          );
        },
      })
    );
  };

  const openEditProductModal = (data) => {
    setComponent(
      getAddProductModal({
        data: {
          ...data,
          colors: data.colors.map((color) => {
            return {
              value: color.toLowerCase(),
              label: color.toLowerCase(),
              color: color.toLowerCase(),
            };
          }),
          sizes: data.sizes.map((size) => {
            return {
              name: size,
              value: size.toLowerCase(),
            };
          }),
          category: data.category?._id,
          subCategory: data.subCategory?._id,
        },
        title: "Edit Product",
        onClose: closeModal,
        onConfirm: (data, reset) => {
          const dataToSend = {
            ...data,
            sizes: data.sizes.map((size) => size.value),
            colors: data.colors.map((color) => color.value),
          };
          dispatch(
            productsActions.updateProduct(
              dataToSend,
              {
                page: productsPagination.pageNumber,
                limit: productsPagination.pageSize,
              },
              () => {
                reset();
                closeModal();
              }
            )
          );
        },
      })
    );
  };

  const removeProduct = (product) => {
    setComponent(
      openConfirmationModal({
        title: "Delete Product Confirmation",
        description: `Are you sure to delete '${product.productName}?`,
        closeModal,
        onConfirm: () => {
          dispatch(
            productsActions.deleteProduct(
              product._id,
              {
                page: productsPagination.pageNumber,
                limit: productsPagination.pageSize,
              },
              closeModal
            )
          );
        },
      })
    );
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      formData.append("file", selectedFile);

      // Await dispatch if needed to ensure upload completion
      await dispatch(
        productsActions.uploadProductsInBulk(formData, {
          page: productsPagination.pageNumber,
          limit: productsPagination.pageSize,
        })
      );
    }

    // Reset input after dispatch
    event.target.value = "";
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting[0]?.id, sorting[0]?.desc]);

  return (
    <Container fluid className="pb-3">
      {showModal && modalElement}
      <Header
        title="Products"
        search={search}
        buttonName="+ Add"
        setOnSearch={setSearch}
        buttonOnClick={() => openAddNewProductModal()}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <div className="flex items-end justify-end">
          <input
            type="file"
            accept=".xlsx, .xls,.csv"
            style={{ display: "none" }} // Hide the actual input
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <Button
            type="button"
            variant="dark"
            className="flex justify-between gap-2"
            onClick={() => fileInputRef.current?.click()} // Trigger input click when button is clicked
          >
            <FontAwesomeIcon icon={faUpload} />
            Upload In Bulk
          </Button>
        </div>

        <ReactTable
          onSort={onSort}
          sortBy={sorting}
          columns={columns}
          data={allProducts || []}
          onPageChange={onPageChange}
          rowsPerPage={productsPagination.pageSize}
          pageNumber={productsPagination.pageNumber}
          totalRows={productsPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Products;
